<template>
  <LayoutOneColumn
      style="--appHeightContent: var(--appHeightWithoutToolBarBottomBar);"
      class="page-home__layout"
  >
    <h1 class="mt-2 mb-4 text-center text-squeeze">Обратная связь</h1>
    <p class="text-center mt-2">Здесь вы можете отправить нам сообщение</p>

    <v-form v-model="valid" ref="form">
    <label>Имя</label>
    <v-text-field
        outlined
        dense
        color="primary"
        v-model="name"
        :rules="nameRules"
        required
        name="name"
    />

    <label>E-mail</label>
    <v-text-field
        outlined
        dense
        color="primary"
        v-model="email"
        :rules="emailRules"
        required
        name="email"
    />

      <label class="d-none">Телефон</label>
      <v-text-field
          outlined
          dense
          color="primary"
          required
          name="phone"
          class="d-none"
      />

    <label>Сообщение</label>
    <v-textarea
        outlined
        dense
        color="primary"
        v-model="message"
        :rules="messageRules"
        required
    />

      <p>В сообщении можете указать любой предпочитаемый способ связи</p>

      <v-expand-transition>
        <div v-if="error" class="mb-4 error--text">
          {{error}}
        </div>
      </v-expand-transition>

      <v-btn
        depressed
        block
        color="primary"
        large
        @click="send"
        :loading="loading"
        class="mb-4"
    >Отправить</v-btn>
    </v-form>
  </LayoutOneColumn>
</template>

<script>
import LayoutOneColumn from "@waln/components/layouts/LayoutOneColumn.vue";

/**
 * Легкая защита от спама - скрытый инпут и отправка через js
 */

export default {
  name: 'PageFeedback',
  components: {LayoutOneColumn},
  data: () => ({
    valid: false,
    name: '',
    nameRules: [
      value => {
        if (value) return true
        return 'Имя не указано'
      },
    ],
    email: '',
    emailRules: [
      value => {
        if (value) return true

        return 'E-mail не указан'
      },
      value => {
        if (/.+@.+\..+/.test(value)) return true

        return 'Некорректный E-mail'
      },
    ],
    message: '',
    messageRules: [
      value => {
        if (value) return true
        return 'Сообщение пустое'
      },
    ],
    error: null,
    loading: false,
  }),
  methods: {
    async send() {
      this.$refs.form.validate();

      if (!this.valid) {
        this.error = 'Не все данные введены верно';
        return;
      }

      this.error = null;
      this.loading = true;

      try {
        // eslint-disable-next-line no-unused-vars
        const response = await fetch('https://functions.yandexcloud.net/d4ec3ah40l5mb5bqms09', {
          method: 'POST',
          body: JSON.stringify({
            type: 'feedback',
            target: 'lh',
            name: encodeURIComponent(this.name),
            email: encodeURIComponent(this.email),
            message: encodeURIComponent(this.message),
            user: this.$store.main.user.value,
            datetime: new Date().toLocaleString('ru'),
            data: null,
          }),
        });

        this.clear();

        this.$api.app.snackSuccess('Сообщение успешно отправлено');
      } catch (e) {
        console.error(e);
        this.$api.app.snackInfo('Сообщение отправлено');
      } finally {
        this.loading = false;
      }
    },
    clear() {
      this.$refs.form.reset();
      this.name = '';
      this.email = '';
      this.message = '';
      this.valid = false;
      this.error = null;
    },
  },
};
</script>