import Vue from 'vue';
import VueYandexMetrika from 'vue-yandex-metrika';

import {createApp, createRouter} from "@waln/starter";

import store from '@/store';
import api from '@/api';
import {createVuetify} from '@/PluginVuetify';

import PageBurger from '@/pages/PageBurger.vue';

import PageHome from "@/pages/PageHome.vue";
import PageAbout from "@/pages/PageAbout.vue";
import PagePortfolio from "@/pages/PagePortfolio.vue";
import PageFeedback from "@/pages/PageFeedback.vue";
import PagePortfolioItem from "@/pages/PagePortfolioItem.vue";

import LogoPic from '@/components/LogoPic.vue';
import PageMaterialApproach from "@/pages/PageMaterialApproach.vue";
import PageMaterialFAQ from "@/pages/PageMaterialFAQ.vue";
import PageMaterialLackhiteShort from "@/pages/PageMaterialLackhiteShort.vue";
import PageMaterialPWA from "@/pages/PageMaterialPWA.vue";
import PageMaterialSEO from "@/pages/PageMaterialSEO.vue";
import PageMaterialTeam from "@/pages/PageMaterialTeam.vue";
import PageMaterialTech from "@/pages/PageMaterialTech.vue";
import PageMaterialWebResearch from "@/pages/PageMaterialWebResearch.vue";
import PageSettings from "@/pages/PageSettings.vue";
import PageDev from "@/pages/PageDev.vue";
import PageAll from "@/pages/PageAll.vue"

// import LogoText from '@/components/LogoText.vue';

// function removeQueryParams(to) {
//   if (Object.keys(to.query).length)
//     return { path: to.path, query: {}, hash: to.hash }
// }
//
// function removeHash(to) {
//   if (to.hash) return { path: to.path, query: to.query, hash: '' }
// }

const routes = [
  {
    path: '/home',
    name: 'home',
    component: PageHome,
    // wireframe: WireframePage,
    meta: {
      nestingLevel: 0,
      // isSaveScrollTop: true,
    },
  },
  {
    path: '/about',
    name: 'About',
    component: PageAbout,
    // FIXME: fix page blinking bug
    // component: () => import(/* webpackChunkName: "about" */ '../pages/PageAbout.vue'),
    meta: {
      nestingLevel: 1,
    },
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: PagePortfolio,
    // component: () => import(/* webpackChunkName: "portfolio" */ '@/pages/PagePortfolio.vue'),
    meta: {
      nestingLevel: 0,
    },
  },
  {
    path: '/portfolio/:id',
    name: 'PortfolioItem',
    component: PagePortfolioItem,
    meta: {
      nestingLevel: 1,
    },
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: PageFeedback,
    // component: () => import(/* webpackChunkName: "feedback" */ '@/pages/PageFeedback.vue'),
    meta: {
      nestingLevel: 0,
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: PageSettings,
    meta: {
      nestingLevel: 1,
    },
  },
  {
    path: '/dev',
    name: 'Dev',
    component: PageDev,
    meta: {
      nestingLevel: 2,
    },
  },
  {
    path: '/materials/approach',
    name: 'MaterialApproach',
    component: PageMaterialApproach,
    meta: {
      nestingLevel: 1,
    },
  },
  {
    path: '/materials/faq',
    name: 'MaterialFAQ',
    component: PageMaterialFAQ,
    meta: {
      nestingLevel: 1,
    },
  },
  {
    path: '/materials/lackhite-short',
    name: 'MaterialLackhiteShort',
    component: PageMaterialLackhiteShort,
    meta: {
      nestingLevel: 1,
    },
  },
  {
    path: '/materials/pwa',
    name: 'MaterialPWA',
    component: PageMaterialPWA,
    meta: {
      nestingLevel: 1,
    },
  },
  {
    path: '/materials/seo',
    name: 'MaterialSEO',
    component: PageMaterialSEO,
    meta: {
      nestingLevel: 1,
    },
  },
  {
    path: '/materials/team',
    name: 'MaterialTeam',
    component: PageMaterialTeam,
    meta: {
      nestingLevel: 1,
    },
  },
  {
    path: '/materials/tech',
    name: 'MaterialTech',
    component: PageMaterialTech,
    meta: {
      nestingLevel: 1,
    },
  },
  {
    path: '/materials/web-research',
    name: 'MaterialWebResearch',
    component: PageMaterialWebResearch,
    meta: {
      nestingLevel: 1,
    },
  },
  {
    path: '/all',
    name: 'All',
    component: PageAll,
    meta: {
      nestingLevel: 0,
    },
  },
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/*',
    redirect: '/home',
  },
];

const router = createRouter(routes);

const vuetify = createVuetify({
  isDark: store.settings.isThemeDark.value,
});

Vue.use(VueYandexMetrika, {
  id: process.env.VUE_APP_YANDEX_METRIKA,
  router: router,
  env: process.env.NODE_ENV,
})

createApp({
  router,
  store,
  api,
  PageBurger,
  LogoPic,
  // LogoText,
  vuetify,
});

if (!store.main.user.value) {
  store.main.user.value = crypto.randomUUID();
}

// after creating and mounting app

import ('./styles/theme-lh.sass');
// import ('../../../onk-client/src/styles/theme-onk.sass');