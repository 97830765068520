<template>
  <WireframeNested title="О приложении">
    <LayoutOneColumn class="pa-2">
      <h4>Приложение</h4>

      <p>LACKHITE</p>

      <h4>Версия</h4>

      <p @dblclick="openDev">127.0.0.1</p>

      <h4>Описание</h4>

      <p>
        Этот сайт выполнен в виде приложения, чтобы можно было начать знакомиться с нашими работами, не открывая портфолио
      </p>

<!--      <h4>Документы</h4>-->
<!--      <ul>-->
<!--        <li><a href="#" @click.prevent="snackDev">Политика обработки персональных данных</a></li>-->
<!--        <li><a href="#" @click.prevent="snackDev">Публичная оферта</a></li>-->
<!--        <li><a href="#" @click.prevent="snackDev">Авторское право</a></li>-->
<!--        <li><a href="#" @click.prevent="snackDev">Дисклеймер</a></li>-->
<!--        <li><a href="#" @click.prevent="snackDev">Реквизиты</a></li>-->
<!--      </ul>-->

      <p class="mt-2">
        © {{new Date().getFullYear()}} LACKHITE
      </p>

      <!-- TODO: contacts-->
    </LayoutOneColumn>
  </WireframeNested>
</template>

<script>
import WireframeNested from '@waln/components/layouts/WireframeNested.vue';
import LayoutOneColumn from "@waln/components/layouts/LayoutOneColumn.vue";

export default {
  name: 'PageAbout',
  components: {LayoutOneColumn, WireframeNested},
  methods: {
    snackDev() {
      this.$api.app.snackDev();
    },
    openDev() {
      this.$api.app.routerPush('/dev');
    },
  },
};
</script>
