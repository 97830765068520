<template>
  <WireframeNested title="Опыт компаний">
    <LayoutOneColumn class="pa-2">
      <p>
        TODO: про продвинутое использование веб технологий и про опыт их применения компаниями
      </p>
    </LayoutOneColumn>
  </WireframeNested>
</template>

<script>
import LayoutOneColumn from "@waln/components/layouts/LayoutOneColumn.vue";
import WireframeNested from "@waln/components/layouts/WireframeNested.vue";

export default {
  name: "PageMaterialWebResearch",
  components: {WireframeNested, LayoutOneColumn}
}
</script>

<style scoped>

</style>