<template>
  <div
      :key="question"
      @click="$emit('click')"
      class="form-poll"
      :class="($vuetify.breakpoint.smAndUp ? '' : 'form-poll-mobile')"
  >
    <div class="d-flex flex-column" style="width: 100%">
      <div class="text-center mb-2 mb-sm-3" style="min-height: 32px">
        {{question}}
      </div>

      <v-expand-transition>
<!--        mb-1 mb-sm-3-->
        <div v-if="answered" class="text-center " style="width: 100%">
          <div class="pb-2 pb-sm-3">
            Ваш ответ: <span class="font-weight-bold text-squeeze">{{variants[answerIndex]}}</span>
          </div>

          <div
              v-if="canReAnswer"
              class="d-flex justify-center pb-2 pb-sm-3"
          >

          <v-btn
              class="form-poll__variant"
              outlined
              color="primary"
              @click="cancel"
              :style="$vuetify.breakpoint.mdAndUp ? '' : 'width: 100%'"
          >
            Переголосовать
          </v-btn>
          </div>
        </div>
      </v-expand-transition>

        <v-expand-transition>
        <div
            v-if="!answered"
            class="d-flex flex-column flex-md-row justify-center"
        >
          <v-btn
              v-for="(variant, variantIndex) of variants"
              :key="`variant-${variantIndex}`"
              class="form-poll__variant mx-md-1 my-1"
              depressed
              color="primary"
              @click="sendAnswer(variantIndex)"
              :loading="!answered && answerIndex !== null && answerIndex === variantIndex"
          >
            {{variant}}
          </v-btn>
        </div>
        </v-expand-transition>

      <v-expand-transition>
        <div
            v-if="afterAnswer && answered"
            class="text-center"
            style="color: gray"
        >{{ afterAnswer}}</div>
      </v-expand-transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormPoll',
  props: {
    question: {
      type: String,
      required: false,
    },
    variants: {
      type: Array,
      required: true,
    },
    afterAnswer: {
      type: String,
      required: false,
    },
    canReAnswer: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: Number,
      required: false,
    },
  },
  data: () => ({
    answerIndex: null,
    answered: false,
  }),
  methods: {
    sendAnswer(variantIndex) {
      this.answerIndex = variantIndex;
      this.answered = true;
      this.$emit('input', this.answerIndex);
      this.$emit('answer', this.answerIndex);
    },
    cancel() {
      this.answerIndex = null;
      this.answered = false;
      // this.$emit('answer', -1);
    },
  },
};
</script>

<style scoped>
.form-poll {
  padding: 16px;
  min-height: 126px;
  height: inherit;
  display: flex;
  border-radius: 5px;
  width: 100%;
  background-color: #f6f6f6;
  cursor: pointer;
}
.form-poll__variant {
  color: #2d2c33;
}
</style>