<template>
  <WireframeNested title="Наш подход">
    <LayoutOneColumn class="pa-2">
      <p>
        На рынке разработки ПО на заказ сложилась следующая тенденция. Небольшие проекты
        делаются за фиксированную оплату (fix price), средние по сложности проекты делаются
        по часовой оплате (TTM), а крупные проекты часто делаются с временным выводом персонала
        за штат (outstaffing).
      </p>

      <p>
        В итоге имеем проблему отсутствия заинтересованности компаний заказной разработки
        ПО в автоматизации и оптимизации труда. Так как либо придётся увеличить стоимость
        часа работы сотрудника, либо дополнительно просить фиксированную оплату для поддержки
        автоматизации и оптимизации. Причём, по словам представителей аутсорсинговых компаний,
        заказчиков редко интересует итоговое уменьшение часов разработки или повышенное качество
        продукта.
      </p>

      <p>
        Мы же решили пойти по другому пути и разработали систему разработки программных систем.
        В эту систему входят: библиотеки, фреймворки, методики разработки ПО, методики управления
        персоналом, вспомогательные инструменты для разработки и многое другое.
      </p>

      <p>
        Чтобы избежать проблемы оплаты трудно обоснованных часов или услуг, мы решили работать
        по фиксированной оплате на рынке средних и крупных проектов.
      </p>

      <p>
        С другой стороны мы используем продуктовые практики и методики при разработке.
        Мы понимаем, что требования к результату постоянно меняются. Часто мы сами являемся
        источником изменений требований, так как благодаря нашей экспертизе, мы можем найти
        точки роста ваших бизнес-метрик. Поэтому предпочитаем работу по гибким методологиям
        разработки.
      </p>

      <p>
        Иногда менторим и консультируем стартапы и бизнесы. Так что если у вас нетипичная
        бизнес модель, то, вероятно, будем полезны своей экспертизой.
      </p>
    </LayoutOneColumn>
  </WireframeNested>
</template>

<script>
import WireframeNested from "@waln/components/layouts/WireframeNested.vue";
import LayoutOneColumn from "@waln/components/layouts/LayoutOneColumn.vue";

export default {
  name: "PageMaterialApproach",
  components: {LayoutOneColumn, WireframeNested}
}
</script>

<style scoped>

</style>