<template>
  <v-toolbar
      dense
      :elevation="0"
      color="white"
      style="max-height: 48px;"
  >
    <transition name="transition-fade" mode="out-in" :duration="{enter: 100, leave: 100}">
      <div
          v-if="hasSearch && !$vuetify.breakpoint.mdAndUp && showSearch"
          key="search"
          class="d-flex align-center"
          style="width: 100%;"
      >
        <v-text-field
            key="search-input-mobile"
            ref="searchInputMobile"
            v-if="showSearch"
            :value="search"
            @input="v => $emit('search', v)"
            prepend-inner-icon="mdi-magnify"
            outlined
            dense
            hide-details
            placeholder="Поиск"
            clearable
            clear-icon="mdi-backspace"
        />
        <v-btn
            v-if="hasFilter"
            key="filter-button"
            icon
            @click="toggleFilter"
            :color="showFilter ? 'primary' : 'gray'"
        >
          <v-icon>mdi-filter</v-icon>
        </v-btn>
        <v-btn
            key="search-close-button"
            icon
            @click="closeSearch"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <div
          v-else
          key="main"
          class="d-flex align-center"
          style="width: 100%;"
      >

        <v-tabs
            v-if="isMain && links && $vuetify.breakpoint.mdAndUp"
            v-model="activeTab"
            centered
            style="position: fixed; left: 0;"
            :key="tabsKey"
            ref="tabs"
            hide-slider
        >
          <v-tab
              v-for="(link, i) in links"
              :key="`tab-${i}`"
              :to="link.to"
          >
            {{ link.name }}
          </v-tab>
        </v-tabs>

        <v-btn
            key="back-mobile-button"
            v-if="!isMain && !$vuetify.breakpoint.mdAndUp"
            icon
            @click="back"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-btn
            v-if="$store.meta.isPageBurgerExists && isMain"
            key="logout"
            @click="openBurger"
            icon
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>

        <LogoPic
            v-if="$store.meta.isLogoPicExists"
            key="logo-pic"
            :style="`max-width: ${$vuetify.breakpoint.mdAndUp ? 40 : 30}px;`"
            style="cursor: pointer; z-index: 1;"
            @click="openHome"
        />
        <LogoText
            v-if="$store.meta.isLogoTextExists && isMain"
            key="logo-text"
            class="ml-2"
            :style="`max-width: ${$vuetify.breakpoint.mdAndUp ? 70 : 50}px;`"
            style="cursor: pointer; z-index: 1;"
            @click="openHome"
        />

        <v-toolbar-title
            v-if="title"
            key="title"
            class="ml-2"
        >{{ title }}</v-toolbar-title>

        <v-spacer key="spacer1"/>


        <div
            v-if="isMain && $vuetify.breakpoint.mdAndUp"
            class="d-flex align-center justify-end"
            style="width: 160px; min-width: 160px;"
        >
          <v-btn
              v-if="$store.auth.hasAuth"
              key="profile"
              @click="openProfile"
              icon
          >
            <v-icon>mdi-account</v-icon>
          </v-btn>
          <v-btn
              v-if="$store.auth.hasAuth"
              key="logout"
              @click="signOut"
              icon
          >
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </div>

        <template v-if="hasSearch && !$vuetify.breakpoint.mdAndUp && !showSearch">
          <v-btn
              key="search-button"
              icon
              @click="openSearch"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>

        <template v-else-if="hasSearch && $vuetify.breakpoint.mdAndUp">
          <v-text-field
              key="search-input-desktop"
              ref="searchInputDesktop"
              :value="search"
              @change="v => $emit('search', v)"
              prepend-inner-icon="mdi-magnify"
              outlined
              dense
              hide-details
              placeholder="Поиск"
              clearable
              clear-icon="mdi-backspace"
          />
          <v-spacer key="spacer2"/>
          <div style="width: 72px"></div>
        </template>

        <v-btn
            key="back-desktop-button"
            v-if="!isMain && $vuetify.breakpoint.mdAndUp"
            icon
            @click="back"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>

      </div>
    </transition>
  </v-toolbar>
</template>

<script>
export default {
  name: 'BarTool',
  props: {
    isMain: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
    },
    hasSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    search: {
      type: String,
      required: false,
    },
    showFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    links: {
      type: Array,
      required: false,
      default: function () {
        return this.$store.main.linksData.main.filter(el => !el.mobileOnly);
      },
    },
  },
  data: () => ({
    showSearch: false,
    activeTab: null,
    tabsKey: 1,
  }),
  methods: {
    back() {
      this.$api.app.routerBack();
    },
    openSearch() {
      this.showSearch = true;
      this.$emit('search', this.search);
      // setTimeout(() => {
      //   if (this.$vuetify.breakpoint.mdAndUp) {
      //     this.$refs.searchInputDesktop.$el.focus();
      //   } else {
      //     this.$refs.searchInputMobile.$el.focus();
      //   }
      // }, 200);
    },
    closeSearch() {
      this.showSearch = false;
      this.$emit('search-close');
    },
    toggleFilter() {
      this.$emit('toggle-show-filter');
    },
    openProfile() {
      this.$api.app.routerPush('/profile');
    },
    openSettings() {
      this.$api.app.routerPush('/settings');
    },
    signOut() {
      this.$api.app.openConfirmDialog({
        title: 'Выход из аккаунта',
        text: 'Вы уверены, что хотите выйти из аккаунта?',
        ok: () => {
          this.$api.auth.signOut();
        },
      });
    },
    openBurger() {
      this.$api.app.openBurger();
    },
    openHome() {
      if (this.$route.path === '/home') {
        return;
      }
      this.$api.app.routerPush('/home');
    },
  },
  mounted() {
    /**
     * Vuetify tabs slider offset bug can be fixed by triggering resizing callback or re-rendering component
     * after some delay or $nextTicks. But actually this is non-100%-working solution.
     *
     * Other 100%-working ways:
     * - use default font,
     * - no mount tabs before loading custom font,
     * - set fixed size of tabs,
     * - hiding slider :)
     */

    // dirty hack vuetify tabs slider offset bug
    // https://github.com/vuetifyjs/vuetify/issues/13238#issuecomment-820316745
    // https://github.com/vuetifyjs/vuetify/issues/4733#issuecomment-729811279
    // setTimeout(() => {
    //   this.$refs.tabs?.onResize();
    // }, 200);

    // OWN dirty hack vuetify tabs slider offset bug
    // const interval = setInterval(() => {
    //   try {
    //     const left = this.$refs.tabs.slider.left;
    //     const leftTarget = Array.from(this.$refs.tabs.$el.leftTarget.childNodes[0].childNodes[1].childNodes[0].childNodes)
    //         .filter(el => el.classList.contains('v-tab'))
    //         .find(el => el.classList.contains('v-tab--active'))
    //         .getBoundingClientRect()
    //         .left;
    //     //console.log(left, leftTarget, Math.floor(leftTarget));
    //     //debugger;
    //     // need to wait loading custom fonts
    //     if (left === Math.floor(leftTarget)) {
    //       clearInterval(interval);
    //     }
    //     // eslint-disable-next-line no-empty
    //   } catch {}
    // }, 50);
  },
};
</script>

<style>
/* dirty hack vuetify tabs slider offset bug */
.v-tab {
  min-width: 150px;
  max-width: 150px;
}
</style>
