<template>
  <div class="layout-one-column">
    <div class="layout-one-column__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LayoutOneColumn',
};
</script>

<style scoped>

</style>