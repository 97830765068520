import AppApi from "@waln/utils/AppApi";

// const currentVersion = process.env.VUE_APP_VERSION;

class AppApiNew extends AppApi {
  openBurger() {
    this.routerPush('/about');
  }
}

export default {
  app: new AppApiNew(),
  auth: {
    // eslint-disable-next-line no-unused-vars
    async signIn(login, password) {
      try {
        this.$store.auth.isAuthLoading = true;
        await new Promise(r => setTimeout(r, 300));
        this.$store.settings.isAuth = !!this.$store.auth.authToken;
        this.$api.app.routerPush('/home');

        // high cohesion (???)
        this.$api.main.init();
        this.$store.auth.isAuthLoading = false;
      } catch (e) {
        console.error(e);
        this.$api.app.snackInfo('Возникли проблемы при входе в аккаунт');
      }
    },
    async signOut() {
      try {
        this.$store.settings.isAuth = false;
        this.$store.app.redirect = null;
        await this.$api.app.routerPush('/login');
      } catch (e) {
        console.error(e);
        this.$api.app.snackInfo('Возникли проблемы при выходе из аккаунта');
      }
    },
  },
  main: {
    init() {
      // Do nothing
    },
  },
};
