<template>
  <WireframeNested title="Про PWA">
    <LayoutOneColumn class="pa-2">

      <p>
        PWA хорош еще, когда функциональность еще меняется под пользователей,
        ты ищешь их болевые точки, решаешь, дожимаешь. И тебе пока плевать на
        сегмент слабых девайсов (ну и чуть на ограниченных). На него не плевать,
        когда ты большой, или дожимаешь сегмент рынка
      </p>

      <p>
        Вы уже должны были понять, что PWA - невероятно полезный концепт. Однако,
        если вы возьмете крутые программные продукты и запишите видео с экрана
        нативных приложений и PWA, а потом сравните из, то увидите, что есть немало различий.
      </p>

      <p>/////////</p>

      (после инфы о приростах от PWA)

      <p>
        Но если посмотреть внимательно все PWA решения, то можно обнаружить намек
        некоторой халтурной работы. Почти у всех компаний из поста(?) было нативное
        приложение, и почти все компании делали PWA как канал привлечения в нативное
        приложение. А теперь представьте увеличение показателей при разработке
        веб-приложения по качеству на уровне хорошего качественного нативного приложения.
        У нас пока что мало данных для аналитики и прогнозирования, но по нашему
        экспертному мнению можем сказать, что это не менее x10 ключевых параметров.
        Это при условии выживания максимума из продукта.
      </p>

      <p>
        [PWA + WALN + нагрузка + Product + UX + marketing] [набрать 4-5 кейсов для факта].
      </p>

    </LayoutOneColumn>
  </WireframeNested>
</template>

<script>
import WireframeNested from "@waln/components/layouts/WireframeNested.vue";
import LayoutOneColumn from "@waln/components/layouts/LayoutOneColumn.vue";

export default {
  name: "PageMaterialPWA",
  components: {LayoutOneColumn, WireframeNested}
}
</script>

<style scoped>

</style>