<template>
  <div class="bar-bottom-simple" :class="$vuetify.theme.isDark ? 'theme--dark' : 'theme--light'">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BarBottomSimple',
};
</script>

<style scoped>
.bar-bottom-simple {
  bottom: 0;
  display: flex;
  left: 0;
  /*justify-content: center;*/
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);

  position: fixed;
  z-index: 4;
  height: 48px;
  transform: none;
}

.theme--light.bar-bottom-simple {
  background-color: #FFFFFF;
  color: rgba(0, 0, 0, 0.87);
}
</style>