<template>
  <LayoutOneColumn
      style="--appHeightContent: var(--appHeightWithoutToolBarBottomBar);"
      class="page-home__layout"
      ref="layout"
      key="home"
  >
    <!--    <h2>LACKHITE</h2>-->
    <!--    <h2>lackhite</h2>-->

<!--    <h3 class="mb-4">ЛЭКХАЙТ - <span style="font-weight: normal">аутсорс с продуктовым подходом</span></h3>-->

    <h1 class="mt-2 mb-4 text-center">ЛЭКХАЙТ</h1>

    <p class="mb-4 text-center">аутсорс с продуктовым подходом</p>

    <h4>ABSTRACT</h4>

    <p>Мы разработчики программных продуктов с опытом работы в продуктовых компаниях. Мы предоставляем возможность компаниям без собственных in-house команд разработчиков обладать продуктами на уровне с компаниями, у которых десятки и сотни собственных разработчиков.</p>

    <h4>INTRODUCTION</h4>

    <p>
      Наша цель - рост вашего бизнеса после сотрудничества с нами. То есть мы отталкиваемся от ваших продуктов
      и сервисов и выявляем решения, которые важны для вашего бизнеса.
    </p>

    <LayoutCards :block="true">
      <CardLink
          title="Наш подход"
          description="Обзор рыночной ситуации и предыстория нашего подхода"
          icon="mdi-information-variant"
          to="/materials/approach"
          :block="true"
      />
    </LayoutCards>

<!--    <h4>Про продуктовый подход</h4>-->

<!--    <p>TODO: Тут должно быть краткое объяснение нашего подхода. Раз уж даже в слоган вынесли.</p>-->

<!--    <div>Мы выделяем следующие стадии развития продукта:</div>-->

    <h4>Кому подходим мы</h4>

    <div>Мы выделяем следующие стадии развития продукта:</div>
    <ul>
      <li><b>MVP</b> (Минимально жизнеспособный продукт). Это продукт с реализованной основной функциональностью, решающей ключевые задачи пользователей.</li>
      <li><b>MLP</b> (Минимально привлекательный продукт). По факту это MVP с привлекательным и конкурентным дизайном UX (пользовательского опыта) и UI.</li>
      <li><b>ADD-features</b> (Дополнительная функциональность). Это функциональность, решающая неосновные задачи пользователя и позволяющая изучить возможности позитивного изменения функциональности продукта.</li>
      <li><b>WOW-features</b> (Вау-функциональность). Визуальные и интерактивные решения для повышения виральности.</li>
    </ul>

    <p>
      Мы полезны на стадии MVP, если вы еще не сформулировали требования к продукту. Можем выступить в качестве бизнес аналитиков.
    </p>

    <p>
      Мы полезны на стадии MLP, чтобы опыт использования продукта соответствовал рыночным ожиданиям.
    </p>

    <p>
      Также мы можем предложить дополнительную функциональность, на основе данных об использовании которой будет понятен путь пользователя, что позволит составить план инкрементального улучшения продукта для достижения product market fit.
    </p>

    <p>
      Вау-эффекты - это прикольно, конечно. И делать мы их умеем. Но почти всегда рекомендуем думать про продукт и потенциал развития. Если продукт не решает боль потребителя, тут никакие эффекты не помогут.
    </p>

    <FormPoll
        v-if="isShowPollMeme"
        question="Как вы бы отнеслись к тому, если прям здесь был какой-то мем или шутка?"
        :variants="['Положительно', 'Без разницы', 'Отрицательно']"
        afterAnswer="Вы увидели пример применения нехитрого метода сбора обратной связи с пользователей"
        class="mb-2"
        @answer="answerMeme"
        v-model="$store.main.pollMemeAnswer.value"
    />

    <h4>Кто подходит нам</h4>

    <p>
      У нас исторически сложился определенный метод фильтрации заказчиков.
    </p>

    <ul>
      <li>
        Вы понимаете, почему <b>качественная разработка</b> программных продуктов может обходиться компаниям очень <b>дорого</b>.
      </li>
      <li>
        У вас помимо бюджета на разработку продукта имеется соразмерный <b>бюджет на доработку</b> продукта,
        в случае если вы поймете, что для бизнеса это будет выгодно.
      </li>
      <li>
        У вас нет иллюзии в дешевизне привлекаемой в продукт аудитории, и у вас <b>посчитан бюджет
        на маркетинг</b>, а не взят с потолка.
      </li>
      <li>
        Вы должны быть <b>заинтересованы в успехе</b> продукта и бизнеса. Обычно больше всего мотивированы учредители,
        но бывают и мотивированные сотрудники. Зачем нам это? Люди, не заинтересованные в росте бизнеса, могут мешать
        принятию полезных решений, а также не любят риски и эксперименты, без которых невозможно тестировать
        бизнес гипотезы.
      </li>
<!--      <li>Никаких "ASAP", "Дедлайн - вчера" и других горящих сроков.</li>-->
      <!--<li>Бюджет на разработку от 5 млн. рублей.</li>-->
    </ul>

<!--    <p>(Позже здесь вместо первого пункта будет ссылка на статью, которая объясняет почему разработка может быть такой дорогой. Ну а пока нам проще работать с теми, кто это и так знает)</p>-->

    <h4>Алгоритм сотрудничества</h4>

    <ul>
      <li>
        <b>Этап 0.</b> Обсуждаем продукт, бизнес, сотрудничество. Если понимаем, что не сотрудничаем,
        то даем бесплатную консультацию (если нужно, конечно).
      </li>
      <li>
        <b>Этап 1.</b> Обсуждаем, согласовываем, утверждаем требования, описание продукта. Реализуем концепт
        демо клиентского приложения за символическую оплату (получится что-то вроде интерактивного мокапа).
        Суть этапа - утверждение основной компоновки и группы фичей.
      </li>
      <li>
        <b>Этап 2.</b> На основе требований, описаний и концепт демо описываем, согласовываем и утверждаем
        требования к конечной версии продукта. Реализуем прототип. Суть этапа - утверждение архитектуры данных
        и бизнес логики приложения.
      </li>
      <li>
        <b>Этап 3.</b> Реализуем продукт, тщательно тестируем, фиксим.
      </li>
      <li>
        <b>Этап 4.</b> Эксплуатация.
      </li>
    </ul>

    <h4>Технические подробности</h4>

    <p>
      Мы делаем кроссплатформенные кроссбраузерные решения не стеке веб технологий. Вот тут мы плавно подходим
      к еще одному ответу на вопрос "Почему мы?". Мы специально делаем веб приложения таким образом, чтобы
      утереть нос нативным. Мы реализовали технологию, которая позволяет выглядеть веб приложениям как нативным.
      Для соответствия веб и нативных решений с точки зрения пользователя мы разрабатывали одни и те же решения на
      нативных и веб технологиях, чтобы достичь максимального соответствия.
    </p>

    <p>
      После разрешения проблем ненативности веба остаются только его плюсы, а именно: высокая скорость разработки,
      возможность использования приложений без установки через браузер, высокая скорость обновления, низкая стоимость
      поддержки приложений и многое другое.
    </p>

<!--    <p>-->
<!--      Подробнее про продвинутое использование веб технологий и про опыт их применения компаниями читайте в статье в нашем блоге.-->
<!--    </p>-->

    <p>
      Кратко некоторые технические детали для понимающих. Предпочитаем nodejs, vuejs, postgres, nginx, docker, yandex cloud.
      Любим статику и оффлайн-first, много кэшируем, заботимся о перформанс метриках, мастерим devops, не забываем про SEO.
    </p>


<!--    <LayoutCards>-->
<!--      <CardLink-->
<!--          title="Обзор опыта компаний"-->
<!--          description="Про продвинутое использование веб технологий"-->
<!--          icon="mdi-information-variant"-->
<!--          to="/materials/web-research"-->
<!--      />-->

<!--      <CardLink-->
<!--          title="Технические подробности"-->
<!--          description="Про технологии, процессы, WALN"-->
<!--          icon="mdi-information-variant"-->
<!--          to="/materials/tech"-->
<!--      />-->

<!--      <CardLink-->
<!--          title="Про SEO"-->
<!--          description="Кратко про индексацию в поисковиках"-->
<!--          icon="mdi-information-variant"-->
<!--          to="/materials/seo"-->
<!--      />-->
<!--    </LayoutCards>-->

    <h4>CONCLUSION</h4>

    <p>
      Короче. Делаем кроссплатформенные приложения, причем делаем быстро и с приемлемым уровнем качества
      (дизайн, скорость работы). Любим фикс оплату. Готовы к изменяющимся требованиям.
      Относительно высокая стоимость на рынке аутсорса, но низкая в сравнении с собственной разработкой.
      Не делаем функциональность, пока не поймем, какую пользу она несет для пользователей.
      Процесс разработки включает доработки продукта во время его эксплуатации.
    </p>

    <p>
      То есть мы не делаем "любые решения любой сложности". Мы делаем конкретные вещи определенным образом.
      Пишите, чтобы понять, насколько мы подходим друг другу.
    </p>

    <LayoutCards :block="true">
      <CardLink
          title="FAQ"
          description="Часто задаваемые вопросы"
          icon="mdi-help"
          to="/materials/faq"
          :block="true"
      />
    </LayoutCards>

    <p>Мы - про партнерские отношения. Возможно, со временем наберём достаточное число проектов и прекратим поиск новых. Информацию на сайте будем обновлять.</p>
  </LayoutOneColumn>
</template>

<script>
import LayoutOneColumn from "@waln/components/layouts/LayoutOneColumn.vue";
import CardLink from "@waln/components/base/CardLink.vue";
import LayoutCards from "@waln/components/layouts/LayoutCards.vue";
import FormPoll from "@waln/components/base/Forms/FormPoll.vue";

// TODO: tooltips: "product market fit", "in house", etc

export default {
  name: 'PageHome',
  components: {FormPoll, LayoutCards, CardLink, LayoutOneColumn},
  data: () => ({
    isShowPollMeme: false,
  }),
  mounted() {
    console.log('mounted home');
    // TODO: use before route enter
    if (this.$store.app.routing.lastOperation === 'back' && this?.$store?.pages?.home?.scrollTop.value) {
      this.$refs.layout.$el.scrollTop = this.$store.pages.home.scrollTop.value;
    } else {
      this.$store.pages.home.scrollTop.value = 0;
      this.$refs.layout.$el.scrollTop = 0;
    }

    if (this.$store.main.pollMemeAnswer.value === -1) {
      this.isShowPollMeme = true;
    }
  },
  methods: {
    snackDev() {
      this.$api.app.snackDev();
    },
    recipeClick() {
      // this.$api.app.routerPush(`/recipes/${recipe.id}`);
      this.$api.app.snackDev();
    },
    async answerMeme() {
      try {
        // eslint-disable-next-line no-unused-vars
        const response = await fetch('https://functions.yandexcloud.net/d4ec3ah40l5mb5bqms09', {
          method: 'POST',
          body: JSON.stringify({
            type: 'poll',
            key: 'meme',
            user: this.$store.main.user.value,
            answer: this.$store.main.pollMemeAnswer.value,
            datetime: new Date().toLocaleString('ru'),
          }),
        });
      } catch (e) {
        console.error(e);
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    const scrollTop = this.$refs.layout.$el.scrollTop;
    if (scrollTop === 0) {
      next();
      return;
    }
    this.$store.pages.home.scrollTop.value = scrollTop;
    next();
  },
  // beforeRouteEnter() {
  //   if (this.$store.app.routing.lastOperation === 'back' && this?.$store?.pages?.home?.scrollTop.value) {
  //     this.$refs.layout.$el.scrollTop = this.$store.pages.home.scrollTop.value;
  //   } else {
  //     this.$refs.layout.$el.scrollTop = 0;
  //   }
  //   if (this.$store.main.pollMemeAnswer.value === -1) {
  //     this.isShowPollMeme = true;
  //   }
  // },
  activated() {
    console.log('activated home');
  },
};
</script>

<style>
/*.page-home__layout ul, .page-home__layout .card-button {*/
/*  margin-bottom: 16px;*/
/*}*/
.page-home__layout .layout-one-column__content {
  max-width: 780px;
  padding: 0 16px; /* 20px for small and more */
}
.page-home__layout .layout-one-column__content ul {
  margin-bottom: 16px;
}
</style>
