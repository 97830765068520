export default {
  main: [
    {
      name: 'Главная',
      to: '/home',
      // icon: 'mdi-home',
      icon: 'fa-solid fa-house',
    },
    {
      name: 'Портфолио',
      to: '/portfolio',
      // icon: 'mdi-briefcase',
      icon: 'fa-solid fa-briefcase',
    },
    // {
    //   name: 'Материалы',
    //   to: '/materials',
    //   icon: 'mdi-book-open-page-variant',
    // },
    {
      name: 'Связаться',
      to: '/feedback',
      // icon: 'mdi-message',
      icon: 'fa-solid fa-comment',
    },
  ],
};