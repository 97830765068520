<template>
  <LayoutOneColumn class="pa-2">
<!--    <LayoutCards>-->
<!--      <CardButton-->
<!--          title="Настройки"-->
<!--          description="Зайдите для настройки приложения"-->
<!--          icon="mdi-cog"-->
<!--          to="/settings"-->
<!--          @click="closePage('/settings')"-->
<!--      />-->
<!--      <CardButton-->
<!--          title="О приложении"-->
<!--          description="О нас, о приложении и юридическая информация"-->
<!--          icon="mdi-information-variant"-->
<!--          to="/about"-->
<!--          @click="closePage('/about')"-->
<!--      />-->
<!--      <CardButton-->
<!--          title="Dev tools"-->
<!--          description="Инструменты для разработки"-->
<!--          icon="mdi-dev-to"-->
<!--          to="/dev"-->
<!--          @click="closePage('/dev')"-->
<!--      />-->
<!--    </LayoutCards>-->
    <!--      TODO: social links -->

    <h4>Приложение</h4>

    <p>LACKHITE</p>

    <h4>Версия</h4>

    <p>127.0.0.1</p>

    <h4>Описание</h4>

    <p>
      Этот сайт выполнен в виде приложения, чтобы можно было начать знакомиться с нашими работами, не открывая портфолио
    </p>

    <p class="mt-2">
      © {{new Date().getFullYear()}} LACKHITE
    </p>
  </LayoutOneColumn>
</template>

<script>
// import CardButton from "@waln/components/base/CardButton.vue";
import LayoutOneColumn from "@waln/components/layouts/LayoutOneColumn.vue";
// import LayoutCards from "@waln/components/layouts/LayoutCards.vue";

export default {
  name: 'PageBurger',
  components: {
    // LayoutCards,
    LayoutOneColumn,
    // CardButton,
  },
  methods: {
    closePage(to) {
      this.$store.app.common.appBar.value = false;
      setTimeout(() => {
        this.$api.app.routerPush(to);
      }, 200);
    },
    signOut() {
      this.$api.app.openConfirmDialog({
        title: 'Выход из аккаунта',
        text: 'Вы уверены, что хотите выйти из аккаунта?',
        ok: () => {
          this.$store.app.common.appBar.value = false;
          setTimeout(() => {
            this.$api.auth.signOut();
          }, 200);
        },
      });
    },
  },
};
</script>
