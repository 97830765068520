<template>
  <LayoutOneColumn
      style="--appHeightContent: var(--appHeightWithoutToolBarBottomBar);"
      class="page-home__layout"
      ref="layout"
      key="portfolio"
  >
    <h1 class="mt-2 mb-4 text-center text-squeeze">Портфолио</h1>

    <p>
      Мы в процессе выхода на определенный сегмент рынка с определенными решениями. Чтобы никого не вводить
      в заблуждение, мы приводим только те работы, которые ближе всего к этому рынку.
    </p>

<!--    <h3 class="text-squeeze mb-1">Наши работы</h3>-->

<!--    <LayoutCards>-->
<!--      <CardLink-->
<!--          title="Расчет НДД"-->
<!--          description="Система расчета налога на дополнительный доход для ПАО «Газпром»"-->
<!--          icon="mdi-briefcase"-->
<!--          to="/portfolio/ndd"-->
<!--      />-->
<!--      <CardLink-->
<!--          title="НСА"-->
<!--          description="Национальный сетевой акселератор для АСИ"-->
<!--          icon="mdi-briefcase"-->
<!--          to="/portfolio/nna"-->
<!--      />-->
<!--    </LayoutCards>-->

    <div
        v-for="(item, itemIndex) of items"
        :key="`item-${itemIndex}`"
        class="d-flex flex-column flex-sm-row"
        style="margin-bottom: 16px;"
    >
      <v-img
          :src="`https://storage.yandexcloud.net/lhs/static/${item.image}`"
          :style="$vuetify.breakpoint.smAndUp ? 'max-width: 300px' : ''"
          height="160"
      />
      <v-card
          class="ma-sm-2 d-flex flex-column"
          :elevation="0"
          style="flex: 1 1 0;"
      >
        <v-card-title class="px-0 py-2 pa-sm-2 pa-md-4">{{item.title}}</v-card-title>
        <v-card-text class="pa-0 pb-2 px-sm-2 pb-sm-2 px-md-4 pb-md-4">{{item.description}}</v-card-text>
        <v-spacer/>
        <div class="pa-0 pb-4 px-sm-2 px-sm-4 pb-sm-2 d-flex align-center justify-center justify-sm-start">
          <!--          <v-spacer/>-->
          <v-btn
              depressed
              color="primary"
              min-width="180"
              :x-large="!$vuetify.breakpoint.smAndUp"
              :style="$vuetify.breakpoint.smAndUp ? '' : 'width: 100%;'"
              :to="item.to"
          >Открыть</v-btn>
        </div>
      </v-card>
    </div>

    <p>
      Работ пока что немного, поэтому в некоторых случаях готовы разработать концептуальный прототип
      (интерактивный мокап) за символическую оплату, чтобы продемонстрировать наши навыки и пополнить портфолио.
    </p>
  </LayoutOneColumn>
</template>

<script>
import LayoutOneColumn from "@waln/components/layouts/LayoutOneColumn.vue";

export default {
  name: 'PagePortfolio',
  components: {LayoutOneColumn},
  data: () => ({
    items: [
      {
        title: 'Расчет НДД',
        description: 'Система расчета налога на дополнительный доход для ПАО «Газпром»',
        to: '/portfolio/ndd',
        image: 'ndd-demo_480x360.png',
      },
      {
        title: 'НСА',
        description: 'Национальный сетевой акселератор для АСИ',
        to: '/portfolio/nna',
        image: 'nna-demo_480x360.png',
      },
    ],
  }),
  mounted() {
    console.log('mounted portfolio');
    if (this.$store.app.routing.lastOperation === 'back' && this?.$store?.pages?.portfolio?.scrollTop.value) {
      this.$refs.layout.$el.scrollTop = this.$store.pages.portfolio.scrollTop.value;
    } else {
      this.$store.pages.portfolio.scrollTop.value = 0;
      this.$refs.layout.$el.scrollTop = 0;
    }
  },
  beforeRouteLeave(to, from, next) {
    const scrollTop = this.$refs.layout.$el.scrollTop;
    if (scrollTop === 0) {
      next();
      return;
    }
    this.$store.pages.portfolio.scrollTop.value = scrollTop;
    next();
  },
  // beforeRouteEnter() {
  //   if (this.$store.app.routing.lastOperation === 'back' && this?.$store?.pages?.portfolio?.scrollTop.value) {
  //     this.$refs.layout.$el.scrollTop = this.$store.pages.portfolio.scrollTop.value;
  //   } else {
  //     this.$refs.layout.$el.scrollTop = 0;
  //   }
  // },
  activated() {
    console.log('activated portfolio');
  },
};
</script>