<template>
  <WireframeNested title="FAQ">
    <LayoutOneColumn class="pa-2">
      <p class="font-weight-bold">Какая общая последовательность разработки приложения в вашей студии?</p>

      <p>Индивидуально. Мы не применяем практики "как есть". Обычно применяем некоторую смесь RUP и Agile.
        Также это зависит от вашей вовлеченности и длины коммуникационных итераций.</p>

      <p class="font-weight-bold">Какую вы предоставляете гарантию на разработанное приложение?</p>

      <p>
        6 месяцев багфикса. Дополнительно консультируем по администрированию, продуктовым метрикам.
        Получается что-то в виде бартера нашей экспертизы на опыт вашего бизнеса. Чем мы больше знаем о бизнесе,
        тем мы полезнее (и, соответственно, дороже).
      </p>

      <p class="font-weight-bold">Сколько вариантов дизайна вы делаете в рамках договора?</p>

      <p>Решаем индивидуально. Бывает ноль, бывают десятки вариаций. Тут суть не Х макетов нарисовать,
        а сделать с учетом подходящих UX практик, отстройкой от конкурентов, и сочетание дизайна с корпоративной историей (речь не только про стиль).</p>

      <p class="font-weight-bold">Какая у вас система оплаты и отчетность?</p>

      <p>
        Бьём на этапы, тоже индивидуально. В каждый этап вкладываем столько усилий, что если по каким-то
        внешним причинам нам придётся тормознуть взаимодействие, чтобы вы были полностью довольны результатами
        работы любого сделанного этапа и смогли переиспользовать результаты с другими подрядчиками.
      </p>

      <p class="font-weight-bold">Как читается lackhite?</p>

      <p>лэкхАйт :)</p>
    </LayoutOneColumn>

  </WireframeNested>
</template>

<script>
import WireframeNested from "@waln/components/layouts/WireframeNested.vue";
import LayoutOneColumn from "@waln/components/layouts/LayoutOneColumn.vue";

export default {
  name: "PageMaterialFAQ",
  components: {LayoutOneColumn, WireframeNested}
}
</script>

<style scoped>

</style>