<template>
  <WireframeNested title="Про SEO">
    <LayoutOneColumn class="pa-2">

      <p>
        Как вы видите адресную строку, анимация перехода страницы, и другой адрес
      </p>

      <p>
        Про SEO vs ux (был мой же текст, Где-то писал)
      </p>

      <p>
        Spa SEO норм. Если хочется супер чего-то, можно пререндер для краулеров.
        Это оч круто. Да, мы знаем что недавно гугл стал называть это решение временным.
        Но решение классное. И ux в порядке, и SEO. Но если уже всёже сильно надо всё
        ближе к идеалу, то ладно, так и быть, сделаем SSR
      </p>

      <p>//////</p>

      <p>
        Про SEO. Мы делаем SPA, так как во главу угла ставим UX. И конечно, хочется норм SEO.
        Для этого мы делаем пререндер и для краулеров отдаём страницы. Да мв знаем, что недавно
        гугл стал называться это "временным" решением. Но тут им самим невыгодна такая
        реализация, их понять можно. Можно сделать и SSR, но это будет заметно дороже,
        так как заметно усложняется реализация и вырастает нагрузка на сервер. Для большинства
        проектов мы рекомендуем то самое "временное" решение.
      </p>

      <p>///////</p>

      <p>
        Если вы разбираетесь в SEO, то вы можете заметить плавность перехода с предыдущей страницы на эту, а также
        изменение адреса страницы
        /main to /materials/waln-seo
      </p>

    </LayoutOneColumn>
  </WireframeNested>
</template>

<script>
import WireframeNested from "@waln/components/layouts/WireframeNested.vue";
import LayoutOneColumn from "@waln/components/layouts/LayoutOneColumn.vue";

export default {
  name: "PageMaterialSEO",
  components: {LayoutOneColumn, WireframeNested}
}
</script>

<style scoped>

</style>