<template>
  <v-bottom-navigation
    v-if="!$vuetify.breakpoint.mdAndUp && links"
    color="primary"
    app
    :value="activeBottomNavigation"
    @change="input"
    :input-value="$store.app.showBottomNavigation"
    mandatory
    :grow="grow ? 'grow' : null"
  >
    <v-btn
      v-for="link in links"
      :key="`bottom-${link.name}`"
      :to="link.to"
      :width="grow ? null : width"
      class="link"
      @click="onClick(link)"
      :class="squeeze ? 'squeeze' : ''"
    >
      <span style="font-size: 9px">{{ link.name }}</span>
      <v-icon>{{ link.icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  name: 'BarBottom',
  props: {
    links: {
      type: Array,
      required: false,
      default: function () {
        return this.$store.main.linksData.main;
      },
    },
    value: {
      type: String,
      required: false,
    },
    grow: {
      type: Boolean,
      required: false,
      default: false,
    },
    width: {
      type: Number,
      required: false,
      default: 70,
    },
    // TODO: mb change squeeze to calc links.length < 5
    squeeze: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function() {
    return {
      activeBottomNavigation: null,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        this.activeBottomNavigation = v;
      },
    },
    '$route.path': {
      immediate: true,
      handler() {
        // FIXME
        // if (!this.$route.meta?.tab) {
        //   return;
        // }
        // const foundIndex = this.links.findIndex(el => el.key === this.$route.meta.tab);
        // if (foundIndex === -1) {
        //   return;
        // }
        // this.activeBottomNavigation = foundIndex;
      },
    },
  },
  computed: {
    linksData() {
      return this.$store.main.linksData;
    },
  },
  methods: {
    input(v) {
      this.activeBottomNavigation = v;
      this.$emit('input', v);
    },
    onClick(link) {
      if (link.onClick) {
        link.onClick();
      }
      this.$store.app.routing.lastOperation = 'push';
      this.$emit('click', link.key);
    },
  },
};
</script>

<style scoped>
@media (max-width: 400px) {
  .link.squeeze {
    max-width: 64px !important;
    min-width: 64px !important;
    padding: 0 !important;
  }
}
</style>
