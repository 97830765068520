<template>
  <LayoutOneColumn
      style="--appHeightContent: var(--appHeightWithoutToolBarBottomBar);"
      class="page-home__layout page-all__layout"
  >
      <LayoutCards>
        <CardLink
            v-for="link of links"
            :key="link.to"
            :title="link.title"
            :description="link.description"
            :icon="link.icon || 'mdi-information-variant'"
            :to="link.to"
        />
      </LayoutCards>
    </LayoutOneColumn>
</template>

<script>
import LayoutOneColumn from "@waln/components/layouts/LayoutOneColumn.vue";
import LayoutCards from "@waln/components/layouts/LayoutCards.vue";
import CardLink from "@waln/components/base/CardLink.vue";

export default {
  name: "PageAll",
  components: {CardLink, LayoutCards, LayoutOneColumn},
  data: () => ({
    links: [
      {
        title: 'Главная',
        description: 'Информация о нас',
        to: '/home',
        icon: 'mdi-home',
      },
      {
        title: 'Портфолио',
        description: 'Про продвинутое использование веб технологий',
        to: '/portfolio',
        icon: 'mdi-briefcase',
      },
      {
        title: 'Связаться',
        description: 'Нажмите, чтобы связаться с нами',
        to: '/feedback',
        icon: 'mdi-message',
      },
      {
        title: 'Наш подход',
        description: 'Обзор рыночной ситуации и предыстория нашего подхода',
        to: '/materials/approach',
      },
      {
        title: 'Обзор опыта компаний',
        description: 'Про продвинутое использование веб технологий',
        to: '/materials/web-research',
      },
      {
        title: 'Технические подробности',
        description: 'Про технологии, процессы, WALN',
        to: '/materials/tech',
      },
      {
        title: 'Про SEO',
        description: 'Кратко про индексацию в поисковиках',
        to: '/materials/seo',
      },
      {
        title: 'FAQ',
        description: 'Часто задаваемые вопросы',
        to: '/materials/faq',
        icon: 'mdi-help',
      },
      {
        title: 'Расчет НДД',
        description: 'Система расчета налога на дополнительный доход для ПАО «Газпром»',
        to: '/portfolio/ndd',
        icon: 'mdi-briefcase',
      },
      {
        title: 'НСА',
        description: 'Национальный сетевой акселератор для АСИ',
        to: '/portfolio/nna',
        icon: 'mdi-briefcase',
      },
      {
        title: 'Краткая история',
        description: 'Краткая история создания нашей компании',
        to: '/materials/lackhite-short',
      },
      {
        title: 'PWA',
        description: 'Про продвинутое использование веб технологий',
        to: '/materials/pwa',
      },
      {
        title: 'Tech',
        description: 'Про используемые нами технические особенности',
        to: '/materials/tech',
      },
      {
        title: 'Team',
        description: 'Про команду и управление командой',
        to: '/materials/team',
      },
      {
        title: 'Dev',
        description: 'Use for dev and debug',
        to: '/dev',
        icon: 'mdi-bug',
      },
    ],
  })
};
</script>

<style>
.page-all__layout {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}
.page-all__layout .layout-one-column__content {
  max-width: 960px;
}
</style>