<template>
  <router-link
      :to="$attrs.to"
      class="card-link"
      :class="block ? 'block' : ''"
      :style="$vuetify.breakpoint.smAndUp ? '' : 'width: 100%'"
  >
    <CardButton
        v-bind="$attrs"
        v-on="$listeners"
        :style="$vuetify.breakpoint.smAndUp ? 'height: 100%' : ''"
        @click="onClick"
        :block="block"
    />
  </router-link>
</template>

<script>
import CardButton from "@waln/components/base/CardButton.vue";

export default {
  name: 'CardLink',
  components: {CardButton},
  props: ['block'],
  methods: {
    onClick() {
      // manual routing management for router-link navigation
      // TODO: use var instead 200
      this.$store.app.routing.manual = true;
      setTimeout(() => {
        this.$store.app.routing.manual = false;
        this.$store.app.routing.lastOperation = 'push';
      }, 200);
    },
  },
};
</script>

<style scoped>
.card-link.block {
  width: 100%;
}
.card-link:link { text-decoration: none; }
.card-link:visited { text-decoration: none; }
.card-link:hover { text-decoration: none; }
.card-link:active { text-decoration: none; }
</style>