<template>
  <div
      v-ripple="{ class: `primary--text` }"
      :key="title"
      @click="$emit('click')"
      class="card-button"
      :class="($vuetify.breakpoint.smAndUp ? '' : 'card-button-mobile') + ' ' +  (block ? 'block' : '')"
  >
    <div class="d-flex flex-column">
      <div class="d-flex align-center mb-1 mb-sm-3" style="height: 32px">
        <v-avatar
            v-if="icon"
            size="32"
            color="primary"
            class="mr-2"
        >
          <v-icon dark dense>{{icon}}</v-icon>
        </v-avatar>
        <h4 class="flex-grow-1 card-button__title">{{title}}</h4>
      </div>
      <div
          v-if="description"
          class="card-button__description"
      >
        {{description}}
      </div>
    </div>
    <v-spacer/>
    <v-icon>mdi-chevron-right</v-icon>
  </div>
</template>

<script>
export default {
  name: 'CardButton',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    to: {
      type: String,
      required: false,
    },
    block: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-button {
  padding: 16px 4px 16px 20px;
  min-height: 126px;
  height: inherit;
  display: flex;
  //flex-direction: column;
  border-radius: 5px;
  width: 270px;
  min-width: 270px;
  background-color: #f6f6f6;
  cursor: pointer;
}
.card-button.block {
  width: 100%;
  min-height: inherit;
}
.card-button * {
  user-select: none;
}
.card-button__title {
  color: #2d2c33;
}
.card-button__description {
  color: #2d2c33;
}
.card-button-mobile {
  width: 100%;
  padding: 8px !important;
  min-height: inherit;
}
</style>