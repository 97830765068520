import Vue, {ref, reactive, customRef} from 'vue';

export const PREFIX = process.env.VUE_APP_PREFIX || 'app';

export function keyWithPrefix(s, prefix = PREFIX) {
  if (!s) {
    throw new Error('Undefined string');
  }
  return `${prefix}_${s}`;
}

export function getLocalStorageItem(s) {
  if (!s) {
    throw new Error('Undefined string');
  }
  return localStorage.getItem(keyWithPrefix(s));
}

export function setLocalStorageItem(s, v) {
  if (!s) {
    throw new Error('Undefined string');
  }
  return localStorage.setItem(keyWithPrefix(s), v);
}

export const localStorageModifiers = {
  boolean: {
    get(k, v = false) {
      const res = getLocalStorageItem(k);
      if (!res) {
        return v;
      }
      return res === 'true';
    },
    set(k, v) {
      setLocalStorageItem(k, v);
    },
  },
  string: {
    get(k, v = '') {
      const res = getLocalStorageItem(k);
      if (!res) {
        return v || '';
      }
      return res;
    },
    set(k, v) {
      setLocalStorageItem(k, v);
    },
  },
  number: {
    get(k, v = 0) {
      const res = getLocalStorageItem(k);
      if (!res) {
        return v;
      }
      if (res.includes('.')) {
        return parseFloat(res);
      }
      return parseInt(res);
    },
    set(k, v) {
      setLocalStorageItem(k, v);
    },
  },
  object: {
    get(k, v = null) {
      const res = getLocalStorageItem(k);
      if (!res) {
        return v;
      }
      return JSON.parse(res);
    },
    set(k, v) {
      setLocalStorageItem(k, JSON.stringify(v));
    },
  },
};

export const localStorageModifiersMap = {
  boolean: 'boolean',
  string: 'string',
  number: 'number',
  bigint: 'number',
  object: 'object',
  array: 'object',
};

export function makeLocalStorageModifiers(value, type) {
  const _type = type || typeof value;
  const mappedType = localStorageModifiersMap[_type];
  if (!mappedType) {
    throw new Error('Incorrect type');
  }
  return localStorageModifiers[mappedType];
}

export function makePersist(obj, key, persistKey = key) {
  const modifiers = makeLocalStorageModifiers(obj[key]);
  const defaultValue = obj[key];
  Object.defineProperty(obj, key, {
    get() {
      return modifiers.get(persistKey, defaultValue);
    },
    set(v) {
      modifiers.set(persistKey, v);
    },
  });
}

export function makeRef(data) {
  return ref(data);
}

export function makeReactive(data) {
  return reactive(data);
}

export function makeObservable(data) {
  return Vue.observable(data);
}

export function createPersistRef(key, value, persistKey = key) {
  // https://vuejs.org/api/reactivity-advanced.html#customref
  const modifiers = makeLocalStorageModifiers(value);
  return customRef((track, trigger) => {
    return {
      get() {
        track();
        return modifiers.get(persistKey, value)
      },
      set(newValue) {
        modifiers.set(persistKey, newValue);
        trigger();
      }
    }
  });
}

export function createPersistGet(key, value, persistKey = key) {
  const modifiers = makeLocalStorageModifiers(value);
  return () => modifiers.get(persistKey, value);
}

export function createPersistSet(key, value, persistKey = key) {
  const modifiers = makeLocalStorageModifiers(value);
  return v => {modifiers.set(persistKey, v)};
}

export function makePersistRef(obj, key, persistKey = key) {
  const defaultValue = obj[key];
  obj[key] = createPersistRef(key, defaultValue, persistKey);
}

// FIXME
// export function createPersistObject(obj) {
//   const res = {};
//   Object.entries(obj).forEach(([k, v]) => {
//     Object.defineProperty(res, k, {
//       get() {
//         return createPersistGet(k, v)();
//       },
//       set(newValue) {
//         createPersistSet(k, v)(newValue);
//       },
//     });
//   });
//   return res;
// }

export function createStoreRequest(obj) {
  // TODO: request manager fields loading, loaded, errored, error(title, subtitle)
  return {
    data: null,
    loaded: ref(false),
    loading: ref(false),
    errored: ref(false),
    error: ref(null),
    ...obj,
  };
}

export function createStorePage(obj) {
  // TODO: page manager (clear/init smth on mount, etc)
  return {
    scrollTop: ref(0),
    ...obj,
  };
}

export function mergeStores(defaultStore, store) {
  Object.entries(defaultStore).forEach(([k1, v1]) => {
    if (!store[k1]) {
      return;
    }
    if (v1 && typeof v1 === 'object') {
      Object.entries(v1).forEach(([k2, v2]) => {
        if (!store[k1][k2]) {
          return;
        }
        if (v2 && typeof v2 === 'object' && store[k1][k2] && typeof store[k1][k2] === 'object') {
          Object.keys(store[k1][k2]).forEach(k => {
            defaultStore[k1][k2][k] = store[k1][k2][k];
          })
        } else {
          defaultStore[k1][k2] = store[k1][k2];
        }
      });
    }
    Object.entries(store[k1]).forEach(([k3, v3]) => {
      if (!v1[k3] || typeof v1[k3] !== 'object') {
        defaultStore[k1][k3] = v3;
      }
    });
  });
  Object.entries(store).forEach(([k4, v4]) => {
    if (!defaultStore[k4] || typeof defaultStore[k4] !== 'object') {
      defaultStore[k4] = v4;
    }
  });
  return defaultStore;
}