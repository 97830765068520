<template>
  <WireframeNested title="Краткая история">
    <LayoutOneColumn class="pa-2">
      <p>
        Когда-то мы уже организовывали свою аутсорс компанию, даже неплохо начали себя чувствовать
        себя на рынке. Но поняли, что наш максимум - это конкуренция с такими же аутсорсерами,
        а уровень аутсорс разработки в целом был ужасный. И мы все разбрелись по компаниям
        за опытом, чтобы научиться делать лучшие программные продукты.
      </p>

      <p>
        И вот спустя 7 лет мы возвращаемся на рынок. Да, совсем другим составом. Но со всеми
        прежними коллегами поддерживаем связь, обмениваемся опытом и помогаем друг другу.
        Мы научились делать продукты "как Google" и местами даже лучше. Очень радует, что
        и аутсорс агентства многому научились, теперь для заказчика стоит большой выбор
        хороших компаний для создания конкурентного продукта.
      </p>

      <p>
        Когда мы станем побольше и более узнаваемыми на рынке, если кому-то будет интересно,
        то напишем уже полную историю LACKHITE.
      </p>

      <p>
        (И может даже раскроем тайну нашего названия)
      </p>
    </LayoutOneColumn>
  </WireframeNested>
</template>

<script>
import WireframeNested from "@waln/components/layouts/WireframeNested.vue";
import LayoutOneColumn from "@waln/components/layouts/LayoutOneColumn.vue";

export default {
  name: "PageMaterialLackhiteShort",
  components: {LayoutOneColumn, WireframeNested}
}
</script>

<style scoped>

</style>