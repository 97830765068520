import Vue from 'vue';
import VueRouter from 'vue-router'

import './registerServiceWorker';
import App from './App.vue';

import {createVuetify} from '@waln/plugins/PluginVuetify';
import {useVirtualScroll} from '@waln/plugins/PluginVirtualScroll';
import {useSanitize} from '@waln/plugins/PluginSanitize';
import {useDisablePinchZoom, useDisableWheelZoom} from '@waln/plugins/PluginGesture';

import '@waln/plugins/PatchWindowSize';
import RequestManager from "@waln/utils/RequestManager";

export function createRouter(routes, hasAuth = false) {
  // TODO: head title and meta
  // How To Update Page Title and Metadata with Vue.js and vue-router
  // https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head

  // TODO: dialog routing
  // Vue Router Architecture and Nested Routes
  // https://dev.to/berniwittmann/my-approach-on-vue-router-architecture-and-nested-routes-2kmo
  // Handling Dialogs with Vue Router
  // https://dev.to/berniwittmann/handling-dialogs-with-vue-router-29ji

  Vue.use(VueRouter);

  const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes,
  });

  router.beforeEach((to, from, next) => {
    if (!router.app.$store.app.wasFirstRouting) {
      router.app.$store.app.wasFirstRouting = true;
      if (to.path !== '/home' ) {
        router.app.$store.app.redirect = (' ' + to.path).slice(1);
        next({ path: '/home' });
        return;
      }
    }
    next();
  });

  router.afterEach((to, from, failure) => {
    if (failure) {
      console.warn('Error', to.fullPath)
    } else {
      // sendToAnalytics(to.fullPath)
    }

    if (to.path === '/home' && router.app.$store.app.redirect) {
      setTimeout(() => {
        const redirect = router.app.$store.app.redirect;
        router.app.$store.app.redirect = null;
        router.app.$api.app.routerPush(redirect);
      }, 200);
    }
  });

  if (hasAuth) {
    router.beforeEach((to, from, next) => {
      if (to.meta?.isExcludedAuth) {
        next();
        return;
      }
      const isAuth = router.app.$store.settings.isAuth;
      if (to.path !== '/login' && !isAuth) {
        next({path: '/login'});
      } else if (to.path === '/login' && isAuth) {
        next({path: '/'});
      } else {
        next();
      }
    });
  }

  return router;
}

export function createApp({router, store, api, PageBurger, PageInstall, LogoPic, LogoText, vuetify}) {
  Vue.config.productionTip = process.env.NODE_ENV === 'production';
  Vue.config.devtools = process.env.NODE_ENV !== 'production';
  Vue.config.debug = process.env.NODE_ENV !== 'production';

  Vue.prototype.$api = api;
  Vue.prototype.$store = store;
  Vue.prototype.$rm = new RequestManager();

  if (PageBurger) {
    Vue.component('PageBurger', PageBurger);
    Vue.prototype.$store.meta.isPageBurgerExists = true;
  }

  if (PageInstall) {
    Vue.component('PageInstall', PageInstall);
    Vue.prototype.$store.meta.isPageInstallExists = true;
  }

  if (LogoPic) {
    Vue.component('LogoPic', LogoPic);
    Vue.prototype.$store.meta.isLogoPicExists = true;
  }

  if (LogoText) {
    Vue.component('LogoText', LogoText);
    Vue.prototype.$store.meta.isLogoTextExists = true;
  }

  // TODO: PatchWindowSize
  useVirtualScroll();
  useSanitize();
  useDisablePinchZoom();
  useDisableWheelZoom();

  // TODO: patch windowApi
  if (!window.crypto.randomUUID) {
    window.crypto.randomUUID = () => ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
  }

  let _vuetify = vuetify;

  if (!_vuetify) {
    _vuetify =  createVuetify({
      isDark: Vue.prototype.$store.settings.isThemeDark.value,
    });
  }

  const app = new Vue({
    router,
    vuetify: _vuetify,
    // watch: {
    //   '$store.settings.isThemeDark.value'(v) {
    //     app.$vuetify.theme.isDark = v;
    //   },
    // },
    render: h => h(App),
  });

// eslint-disable-next-line no-underscore-dangle
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-underscore-dangle
    window.__app = app;
  }

  app.$rm.$app = app;

  Object.keys(app.$api).forEach(k => {
    app.$api[k].$app = app;
    app.$api[k].$router = app.$router;
    app.$api[k].$store = app.$store;
    app.$api[k].$api = app.$api;
    app.$api[k].$rm = app.$rm;
  });

  app.$mount('#app');

  app.$api.main.init();
}

export default {
  createRouter,
  createApp,
};
