<template>
  <v-app
      class="fill-view-height overflow-hidden"
      :class="appClass"
      ref="app"
      v-cloak
  >
<!--    <BarApp-->
<!--        v-if="$route.path !== '/login' && isShowBarApp"-->
<!--        :style="appBarStyle"-->
<!--        class="app-bar"-->
<!--        :class="appBarClass"-->
<!--    />-->

<!--    v-if="$route.path !== '/login' && isShowBarApp"-->

    <template v-if="isShowInstall">
      <transition
          name="transition-fade"
          mode="out-in"
          :duration="{enter: 100, leave: 100}"
          appear
      >
        <PageInstall

            @install="installApp"
            @continue="continueInBrowser"
        />
      </transition>
    </template>

    <template v-else>
<!--      <transition-->
<!--          name="transition-fade-appbar"-->
<!--          mode="in-out"-->
<!--          :duration="{enter: (transitionDuration - 20), leave: 0}"-->
<!--      >-->
        <BarTool
            v-if="isShowBarApp"
            :isMain="true"
            :style="appBarStyle"
            class="app-bar"
            :class="appBarClass"
        />
<!--      </transition>-->

      <v-main class="fill-view-height overflow-hidden pt-0" style="width: 100vw;">
        <transition
            v-if="success"
            :name="transitionData.name"
            :mode="transitionData.mode"
            :duration="{enter: transitionData.duration.enter, leave: transitionData.duration.leave}"
            @before-enter="beforeEnter"
            @after-enter="afterEnter"
            @before-leave="beforeLeave"
            @after-leave="afterLeave"
        >
<!--          <router-view-->
<!--              :key="$route.name"-->
<!--              class="app-window"-->
<!--              :class="routerViewClass + routerViewClassWithBar"-->
<!--              :style="styleRouterView"-->
<!--          ></router-view>-->

<!--          <router-view-->
<!--              :key="$route.name"-->
<!--              class="app-window"-->
<!--              :class="routerViewClass + routerViewClassWithBar"-->
<!--              :style="styleRouterView"-->
<!--              v-slot="{ Component }"-->
<!--          >-->
<!--            <keep-alive include="PageRecipes">-->
<!--              <component :is="Component" />-->
<!--            </keep-alive>-->
<!--          </router-view>-->

<!--          <keep-alive>-->
<!--            <router-view-->
<!--                :key="$route.name + ($route.params.id || '')"-->
<!--                class="app-window"-->
<!--                :class="routerViewClass + routerViewClassWithBar"-->
<!--                :style="styleRouterView"-->
<!--            />-->
<!--          </keep-alive>-->

<!--          <keep-alive v-if="$route.meta.keepAlive">-->
<!--            <router-view-->
<!--                :key="$route.name + ($route.params.id || '')"-->
<!--                class="app-window"-->
<!--                :class="routerViewClass + routerViewClassWithBar"-->
<!--                :style="styleRouterView"-->
<!--            />-->
<!--          </keep-alive>-->
<!--          <router-view-->
<!--              v-else-->
<!--              :key="$route.name + ($route.params.id || '')"-->
<!--              class="app-window"-->
<!--              :class="routerViewClass + routerViewClassWithBar"-->
<!--              :style="styleRouterView"-->
<!--          />-->

          <keep-alive
              :include="includeNames"
              :exclude="excludeNames"
              max="5"
          >
            <!--                :key="$route.name + ($route.params.id || '')"-->
            <router-view
                :key="$route.fullPath"
                class="app-window"
                :class="routerViewClass + routerViewClassWithBar"
                :style="styleRouterView"
            />
          </keep-alive>

<!--          <router-view-->
<!--              v-slot="{ Component }"-->
<!--                        :key="$route.name + ($route.params.id || '')"-->
<!--                        class="app-window"-->
<!--                        :class="routerViewClass + routerViewClassWithBar"-->
<!--                        :style="styleRouterView"-->
<!--          >-->
<!--            <transition-->
<!--                v-if="success"-->
<!--                v-bind="transitionData"-->
<!--                @before-enter="beforeEnter"-->
<!--                @after-enter="afterEnter"-->
<!--                @before-leave="beforeLeave"-->
<!--                @after-leave="afterLeave"-->
<!--            >-->
<!--              <keep-alive-->
<!--                  :include="includeNames"-->
<!--                  :exclude="excludeNames"-->
<!--              >-->
<!--                <component :is="Component" />-->
<!--              </keep-alive>-->
<!--            </transition>-->
<!--          </router-view>-->

        </transition>
      </v-main>

      <BarBottom
          v-if="$route.path !== '/login' && isShowBarBottom"
          :linksType="$route.meta.nav"
          :class="bottomBarClass"
          class="bottom-bar"
          :grow="$store.meta.barBottomGrow"
          :squeeze="$store.meta.barBottomSqueeze"
      />
    </template>

    <DialogConfirm/>

    <BarSnack/>

    <ViewDialog
        v-if="$store.meta.isPageBurgerExists"
        v-model="$store.app.common.appBar.value"
        :title="$store.meta.pageBurgerTitle"
    >
      <PageBurger/>
    </ViewDialog>
  </v-app>
</template>

<script>
import RoutingTransitionsUtils from '@waln/utils/RoutingTransitions';
import DialogConfirm from "@waln/components/base/DialogConfirm.vue";
import BarSnack from "@waln/components/base/BarSnack.vue";
import BarBottom from "@waln/components/base/BarBottom.vue";
import ViewDialog from "@waln/components/base/ViewDialog.vue";
import BarTool from "@waln/components/base/BarTool.vue";
import PatchWindowSize from '@waln/plugins/PatchWindowSize';

export default {
  name: 'App',
  components: {BarTool, ViewDialog, BarBottom, BarSnack, DialogConfirm},
  data() {
    return {
      transitionData: {},
      isShowBarApp: false,
      isShowBarBottom: false,
      routerViewClass: '',
      appBarClass: '',
      bottomBarClass: '',
      PLATFORM_ENV: PatchWindowSize.PLATFORM_ENV,
      BROWSER_ENV: PatchWindowSize.BROWSER_ENV,
      deferredPrompt: null,
      excludedShowBarApp: ['/login'],
    };
  },
  computed: {
    success() {
      return true;
    },
    // isAuth() {
    //   return true;
    // },
    // loading() {
    //   return false;
    // },
    // loaded() {
    //   return true;
    // },
    // errored() {
    //   return false;
    // },
    // error() {
    //   return null;
    // },
    appBarStyle() {
      // WORKS: for leaving route view opacity
      // return {opacity: this.isShowBarApp ? 1 : 0};
      return null;
    },
    transitionDuration() {
      return (this.transitionData?.duration?.enter + this.transitionData?.duration?.leave) || 0;
    },
    styleRouterView() {
      return {
        // 'padding-top': this.$route.meta.nestingLevel === 0 && this.$route.path !== '/login' ? '48px' : '0',
        '--transition-duration': this.transitionDuration,
        '--routing-transition': `${this.transitionDuration}ms ease-out`,
      };
    },
    appClass() {
      let displayMode = 'browser';
      const mqStandAlone = '(display-mode: standalone)';
      if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
        displayMode = 'standalone';
      }
      return this.PLATFORM_ENV.toLowerCase() + ' ' +
        (this.$vuetify.breakpoint.mdAndUp ? 'desktop' : 'mobile') + ' ' +
        (this.isInstalled ? 'installed' : '') + ' ' +
        displayMode;
    },
    routerViewClassWithBar() {
      if (this.$route.meta.nestingLevel === 0) {
        return ` with-app-bar`;
      }
      return '';
    },
    isShowInstall() {
      return this.$store.meta.isPageInstallExists && this.deferredPrompt && this.$store.settings.isShowInstall;
    },
    isInstalled() {
      return !this.deferredPrompt;
    },
    excludeNames() {
      return this.$router.options.routes
          .filter(r => r.component?.name && !r.meta?.keepAlive)
          .map(r => r.component.name)
          .join(',')
    },
    includeNames() {
      return this.$router.options.routes
          .filter(r => r.component?.name && r.meta?.keepAlive)
          .map(r => r.component.name)
          .join(',')
    },
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler(newValue, oldValue) {

        if (!oldValue && this.$route.meta.nestingLevel === 0 && !this.excludedShowBarApp.includes(this.$route.path)) {
          this.isShowBarApp = true;
          this.isShowBarBottom = true;
        }

        if (!oldValue || !newValue) {
          this.transitionData = RoutingTransitionsUtils.transitions.default;
          return;
        }

        const oldRouteMeta = this.$router.getRoutes()
            .find(el => oldValue?.match(el.regex))?.meta;
        const newRouteMeta = this.$route.meta;

        // if (this.isShowBarApp && (newRouteMeta.nestingLevel > 0 || this.excludedShowBarApp.includes(this.$route.path))) {
        //   setTimeout(() => {
        //     this.isShowBarApp = false;
        //   }, this.transitionDuration);
        // }
        // if (this.isShowBarBottom && newRouteMeta.nestingLevel > 0) {
        //   setTimeout(() => {
        //     this.isShowBarBottom = false;
        //   }, this.transitionDuration);
        // }
        if (!this.isShowBarApp && newRouteMeta.nestingLevel === 0 && !this.excludedShowBarApp.includes(this.$route.path)) {
          this.isShowBarApp = true;
          // setTimeout(() => {
          //   this.isShowBarApp = true;
          // }, 100);
        }
        if (!this.isShowBarBottom && newRouteMeta.nestingLevel === 0) {
          this.isShowBarBottom = true;
          // setTimeout(() => {
          //   this.isShowBarBottom = true;
          // }, this.transitionDuration);
        }

        const params = {
          platform: this.PLATFORM_ENV,
          browser: this.BROWSER_ENV,
          manualRouting: this.$store.app.routing.manual,
        }
        // console.log(JSON.stringify(params));
        this.transitionData = RoutingTransitionsUtils.transitionByRoute(
            oldRouteMeta,
            newRouteMeta,
            this.$vuetify.breakpoint,
            params
        );
      },
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    beforeEnter(el) {
      if (this.transitionData.name === 'transition-dialog-left') {
        this.appBarClass = 'd-none';
        this.bottomBarClass = 'd-none';
      }
    },
    // eslint-disable-next-line no-unused-vars
    afterEnter(el) {
      const newRouteMeta = this.$route.meta;
      if (this.isShowBarApp && (newRouteMeta.nestingLevel > 0 || this.excludedShowBarApp.includes(this.$route.path))) {
        this.isShowBarApp = false;
      }
      if (this.isShowBarBottom && newRouteMeta.nestingLevel > 0) {
        this.isShowBarBottom = false;
      }

      if (this.transitionData.name === 'transition-dialog-left') {
        el.style.display = 'none';
        setTimeout(() => {
          el.style.display = null;
        }, 50);
      }
    },
    // eslint-disable-next-line no-unused-vars
    beforeLeave(el) {
      if (this.transitionData.name === 'transition-dialog-left') {
        this.routerViewClass = this.transitionData.name + '-animate';

        // dirty hack for prevent appbar blinking
        setTimeout(() => {
          this.appBarClass = '';
          this.bottomBarClass = '';
        }, 50);
      }

      if (this.transitionData.name === 'transition-dialog-right' ||
          this.transitionData.name === 'transition-dialog-bottom') {
        el.style.display = 'none';
        setTimeout(() => {
          el.style.display = null;
        }, this.transitionDuration);
      }
    },
    // eslint-disable-next-line no-unused-vars
    afterLeave(el) {},
    async installApp() {
      console.log('👍', 'butInstall-clicked');
      const promptEvent = this.deferredPrompt;
      if (!promptEvent) {
        // Отложенный запрос недоступен.
        return;
      }
      // Показать запрос на установку.
      promptEvent.prompt();
      // Записать результат в журнал.
      const result = await promptEvent.userChoice;
      console.log('👍', 'userChoice', result);
      // Сбросить переменную отложенного запроса:
      // prompt() можно вызвать только один раз.
      this.deferredPrompt = null;
      // Скрыть кнопку установки.
      // divInstall.classList.toggle('hidden', true);
    },
    continueInBrowser() {
      this.$store.settings.isShowInstall = false;
    },
  },
  created() {
    if (this.$store.settings.isShowInstall) {
      window.addEventListener('beforeinstallprompt', (event) => {
        // Запрет показа информационной мини-панели на мобильных устройствах.
        event.preventDefault();
        console.log('👍', 'beforeinstallprompt', event);
        // Убираем событие, чтобы его можно было активировать позже.
        this.deferredPrompt = event;
        // Убираем класс «hidden» из контейнера кнопки установки.
        // divInstall.classList.toggle('hidden', false);
      });

      window.addEventListener('appinstalled', (event) => {
        console.log('👍', 'appinstalled', event);
        // Очистить «deferredPrompt» для сборщика мусора
        this.deferredPrompt = null;
      });
    }
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/styles.sass'
@import '@waln/styles/normalize.sass'
@import '@waln/styles/lackhite.scss'
@import '@waln/styles/routing-transitions.sass'
//@import '@waln/styles/fonts-onk.css'
//@import '@waln/styles/theme-onk.sass'
</style>

<style>
.app-window {
  transition: all var(--routing-transition);
}

[v-cloak] {
  display: none;
}
</style>

<style lang="scss">
/*https://arutyunov.me/blog/vue/kak-skryt-shablon-vue-js-poka-on-ne-otrenderitsya-direktiva-v-cloak/*/
//[v-cloak] {
//  display: block;
//  padding: 50px 0;
//
//  @keyframes spinner {
//    to {
//      transform: rotate(360deg);
//    }
//  }
//
//  &:before {
//    content: '';
//    box-sizing: border-box;
//    position: absolute;
//    top: 50%;
//    left: 50%;
//    width: 20px;
//    height: 20px;
//    margin-top: -10px;
//    margin-left: -10px;
//    border-radius: 50%;
//    border: 2px solid #ccc;
//    border-top-color: #333;
//    animation: spinner .6s linear infinite;
//    text-indent: 100%;
//    white-space: nowrap;
//    overflow: hidden;
//  }
//
//  & > div {
//    display: none;
//  }
//}
</style>
