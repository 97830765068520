import {ref} from 'vue';
import {
  createStoreRequest,
  createStorePage,
  createPersistGet,
  createPersistSet,
  mergeStores,
  createPersistRef
} from '@waln/utils/StoreUtils';

import AppStore from "@waln/utils/AppStore";
import LinksData from "./LinksData";

const data = {
  settings: {
    isSaveRecipesSearchParams: createPersistRef('isSaveRecipesSearchParams', true),
    version: createPersistRef('version', '1.2.3'),
  },

  pages: {
    posts: createStorePage({
      postsViewType: ref(1),
      postsType: ref(0),
    }),

    home: createStorePage(),
    portfolio: createStorePage(),
  },

  requests: {
    posts: createStoreRequest(),
  },

  main: {
    linksData: LinksData, // navigation model

    user: createPersistRef('user', null),

    pollMemeAnswer: createPersistRef('poll-meme', -1),
  },

  get static() { return createPersistGet('cook', { cook: null })() },
  set static(v) { createPersistSet('cook', { cook: null })(v) },

  app: {
    snack: {
      timeout: 3210,
    },
  },

  meta: {
    isPageBurgerExists: true,
    pageBurgerTitle: 'О приложении',

    barBottomGrow: true,
    barBottomSqueeze: false,
  },
};

export default mergeStores(AppStore, data);
