<template>
  <WireframeNested title="Команда">
    <LayoutOneColumn class="pa-2">
      <p>
        TODO: про команду и управление командой
      </p>
    </LayoutOneColumn>
  </WireframeNested>
</template>

<script>
import LayoutOneColumn from "@waln/components/layouts/LayoutOneColumn.vue";
import WireframeNested from "@waln/components/layouts/WireframeNested.vue";

export default {
  name: "PageMaterialTeam",
  components: {WireframeNested, LayoutOneColumn}
}
</script>

<style scoped>

</style>