<template>
  <WireframeNested title="Тех. детали">
    <LayoutOneColumn class="pa-2">
      <p>
        Мы делаем кроссплатформенные кроссбраузерные решения не стеке веб технологий.
        Вот тут мы плавно подходим к еще одному ответу на вопрос "Почему мы?".
        Мы специально делаем веб приложения таким образом, чтобы утереть нос нативным.
        Мы реализовали технологию, которая позволяет выглядеть веб приложениям как нативным.
        Собственно, мы её так и назвали, WALN - Web Apps Like Native. Для соответствия
        веб и нативных решения с точки зрения пользователя мы разрабатывали одни и те же
        решения на нативных и веб технологиях, чтобы достичь максимального соответствия.
      </p>

      <p>
        После разрешения проблем ненативности веба остаются только его плюсы, а именно:
        высокая скорость разработки, возможность использования приложений без установки
        через браузер, высокая скорость обновления, низкая стоимость поддержки приложений
        и многое другое.
      </p>

      <p>
        Подробнее про продвинутое использование веб технологий и про опыт их применения
        компаниями читайте в статье в нашем блоге.
      </p>
    </LayoutOneColumn>
  </WireframeNested>
</template>

<script>
import LayoutOneColumn from "@waln/components/layouts/LayoutOneColumn.vue";
import WireframeNested from "@waln/components/layouts/WireframeNested.vue";

export default {
  name: "PageMaterialTech",
  components: {WireframeNested, LayoutOneColumn}
}
</script>

<style scoped>

</style>