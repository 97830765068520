<template>
  <div class="d-flex justify-center flex-wrap layout-cards" :class="block ? 'block' : ''">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'LayoutCards',
  props: ['block'],
};
</script>

<style scoped>
.layout-cards {
  margin-bottom: 16px;
}
.layout-cards:not(.block) > * {
  margin: 6px 0;
}
@media (min-width: 600px) {
  .layout-cards:not(.block) > * {
    margin-left: 6px;
    margin-right: 6px;
  }
}
</style>