<template>
  <div class="fill-view-height wrapper wireframe-nested">
    <BarTool
        :title="title"
        :hasSearch="hasSearch"
        :hasFilter="hasFilter"
        :search="search"
        :showFilter="isShowFilter"
        @search="inputSearch"
        @search-close="closeSearch"
        @toggle-show-filter="toggleShowFilter"
    />
    <transition name="transition-fade" mode="out-in" :duration="{enter: 100, leave: 100}">
      <div
          v-if="!$vuetify.breakpoint.mdAndUp && isShowFilter"
          class="wrapper-filter"
          key="filter"
      >
        <slot name="filter"></slot>
        <BarBottomSimple>
          <div class="d-flex align-center flex-grow-1">
            <v-btn v-if="canFilterReset" @click="resetFilter" outlined color="primary" class="flex-grow-1 mr-1">Сбросить</v-btn>
            <v-btn @click="applyFilter" depressed color="primary" class="flex-grow-1 ml-1">Применить</v-btn>
          </div>
        </BarBottomSimple>
      </div>
      <div v-else key="main">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import BarTool from "@waln/components/base/BarTool.vue";
import BarBottomSimple from "@waln/components/base/BarBottomSimple.vue";

// TODO: mb use back arrow icon for desktop too because native logic of "close" is closing window as router up
export default {
  name: 'WireframeNested',
  components: {BarBottomSimple, BarTool},
  props: {
    title: {
      type: String,
      required: false,
    },
    hasSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    canFilterReset: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    isShowFilter: false,
    search: '',
  }),
  methods: {
    inputSearch(v) {
      if (this.hasFilter) {
        if (!v) {
          this.isShowFilter = true;
          return;
        }
        if (this.isShowFilter) {
          this.isShowFilter = false;
        }
      }
      this.search = v;
    },
    closeSearch() {
      if (this.hasFilter) {
        this.isShowFilter = false;
      }
      this.search = '';
    },
    toggleShowFilter() {
      this.isShowFilter = !this.isShowFilter;
    },
    resetFilter() {
      this.$api.app.openConfirmDialog({
        title: 'Сброс фильтра',
        text: 'Вы уверены, что хотите сбросить параметры фильтрации?',
        ok: () => {
          this.isShowFilter = false;
          this.$emit('reset');
        },
      });
    },
    applyFilter() {
      this.isShowFilter = false;
      this.$emit('apply');
    },
  },
};
</script>

<style scoped>
.wrapper {
  background-color: #fff;
  width: 100%;
}
.wrapper-filter {
  height: var(--appHeightWithoutToolBarBottomBar);
  overflow-y: auto;
}
</style>