<template>
  <WireframeNested title="Dev">
    <LayoutOneColumn class="pa-2">
      <p>
        <v-btn depressed color="primary" @click="resetUser">reset user</v-btn>
      </p>
      <p>
        <v-btn depressed color="primary" @click="resetPollMeme">reset poll meme</v-btn>
      </p>
    </LayoutOneColumn>
  </WireframeNested>
</template>

<script>
import LayoutOneColumn from "@waln/components/layouts/LayoutOneColumn.vue";
import WireframeNested from "@waln/components/layouts/WireframeNested.vue";

export default {
  name: "PageDev",
  components: {WireframeNested, LayoutOneColumn},
  methods: {
    resetUser() {
      this.$store.main.user.value = '';
    },
    resetPollMeme() {
      this.$store.main.pollMemeAnswer.value = -1;
    },
  },
};
</script>

<style scoped>

</style>