<template>
  <v-overlay
      :value="overlay"
      :opacity="0.8"
      class="transition-activator-modal"
  >
    <div
        class="transition-activator-modal__container"
        ref="container"
        v-resize="onResize"
    >
      <div class="d-flex align-center transition-activator-modal__content">
        <!--<div style="z-index: 1">{{moveXC}} {{moveYC}} {{offsetWidth}} {{offsetHeight}} {{zoom}} {{initialDistance}} {{log}}</div>-->
        <v-spacer/>
        <v-btn @click="zoomOut" icon large class="transition-activator-modal__content__button" v-if="zoom">
          <v-icon>fa-solid fa-magnifying-glass-minus</v-icon>
        </v-btn>
        <v-btn @click="zoomIn" icon large class="transition-activator-modal__content__button" v-if="zoom">
          <v-icon>fa-solid fa-magnifying-glass-plus</v-icon>
        </v-btn>
        <v-btn @click="hide" icon large class="transition-activator-modal__content__button">
          <v-icon large>fa-solid fa-xmark</v-icon>
        </v-btn>
      </div>

      <v-img
          v-if="type === 'image'"
          :src="src"
          :style="activatorStyle"
          :class="isActivatorAnimating ? 'animating' : ''"
          @load="onLoadActivator"
          class="transition-activator"
          alt=""
          contain
      />

      <template v-else-if="type === 'video'">
<!--        :class="(isActivatorAnimating ? 'animating' : '') + ' ' + (isShowVideo ? '' : 'd-none')"-->
<!--        :class="(isActivatorAnimating ? 'animating' : '')"-->

        <video
            :style="activatorStyle"

            :class="(isActivatorAnimating ? 'animating' : '') + ' ' + (isShowVideo ? '' : 'd-none')"
            class="transition-activator"
            ref="video"
            src=""
            width="400"
            height="600"
            autoplay
            muted
            loop
            type="video/mp4"
            :poster="srcPoster"
        ></video>
        <v-img
            :src="srcPoster"
            :style="activatorStyle"
            :class="(isActivatorAnimating ? 'animating' : '') + ' ' + (isShowVideo ? 'd-none' : '')"
            @load="onLoadActivator"
            class="transition-activator"
            alt=""
            contain
        />
      </template>

    </div>
  </v-overlay>
</template>

<script>
/**
 * mb refactor smth as https://github.com/worka/vanilla-js-wheel-zoom/blob/master/src/toolkit.js
 */

/**
 * TODO: make all transitions with requestAnimationFrame (moving, scaling, etc)
 */
export default {
  name: "MediaViewer",
  props: {
    source: {
      type: Object,
      required: false,
      default: null,
    },
    sourcePosition: { // center position?
      type: Object,
      required: false,
      default: null,
    },
    targetRef: {
      type: Object,
      required: false,
      default: null,
    },
    target: {
      type: Object,
      required: false,
      default: null,
    },
    targetPosition: { // center position?
      type: Object,
      required: false,
      default: function () {
        return {
          left: '50%',
          top: '50%',
        };
      },
    },
  },
  data: () => ({
    isActivatorAnimated: false,
    isActivatorAnimating: false,
    activatorStyle: {
      display: 'none',
    },
    overlay: false,
    sourceRef: null,

    type: null,
    src: null,
    size: null,
    srcPoster: null,

    isShowVideo: false,

    zoom: 1,
    zoomStep: 1.5,
    zoomMin: 0.4,
    zoomMax: 6,
    // zoomMinBump: 0.1,
    // zoomMaxBump: 5,

    offsetWidth: 0,
    offsetHeight: 0,
    offsetWidthMin: 0,
    offsetHeightMin: 0,
    offsetWidthMax: 0,
    offsetHeightMax: 0,

    touchStartPosXStart: 0,
    touchStartPosYStart: 0,
    // touchStartPosX: 0,
    // touchStartPosY: 0,
    touchStartTimeout: null,
    moving: false,
    movingTimeout: null,
    // movingInterval: null,
    scaling: false,

    eventType: null,

    // for zoom
    initialDistance: 0,
    initialMoveX: 0,
    initialMoveY: 0,
    initialScale: 0,
    moveXC: 0,
    moveYC: 0,

    log: '', // TODO: remove

    previousTimeStamp: null,

    isListenersExist: false,
    requestAnimationFrameId: null,
  }),
  methods: {
    onResize() {
      this.calculateOffsetsLimits();
      this.correctOffsets();
      this.activatorStyle = this.calcActivatorStyle();
    },
    onMoveInputStart(e) {
      if (!this.isActivatorAnimating ||
          e.changedTouches?.length > 1 ||
          e.touches?.length > 1 ||
          this.eventType !== null
      ) {
        // TODO: remove
        this.log = `||${e.changedTouches?.length} ${e.touches?.length} ${this.eventType}`;
        // const t = JSON.stringify(e) + e.touches?.length + e.changedTouches?.length
        // alert(t)
        return;
      }

      this.touchStartPosXStart = Math.round(e.x || e.changedTouches?.[0]?.screenX || 0);
      this.touchStartPosYStart = Math.round(e.y || e.changedTouches?.[0]?.screenY || 0);
      this.moving = true;

      // if (this.movingInterval) {
      //   clearInterval(this.movingInterval);
      // }

      let start = null;
      const step = timestamp => {
        // TODO: if not opened activator
        if (!start) {
          start = timestamp;
        }
        const elapsed = timestamp - start;
        if (this.previousTimeStamp !== timestamp && elapsed > 0) {
          this.activatorStyle = this.calcActivatorStyle();
        }
        this.previousTimeStamp = timestamp;
        this.requestAnimationFrameId = window.requestAnimationFrame(step);
      };
      this.requestAnimationFrameId = window.requestAnimationFrame(step);

      // slow simple implementation variant
      // this.movingInterval = setInterval(() => {
      //   this.activatorStyle = this.calcActivatorStyle();
      // }, 20)
    },
    onMoveInput(e) {
      if (!this.moving) {
        return;
      }

      if (!this.isActivatorAnimating ||
          e.changedTouches?.length > 1 ||
          e.touches?.length > 1 ||
          this.eventType !== null
      ) {
        this.moving = false;
        return;
      }

      // this.log = e.changedTouches?.[0].clientX + ' ' + e.changedTouches?.[0].clientY

      const currentPageX = Math.round(e.x || e.changedTouches?.[0]?.screenX || 0);
      const currentPageY = Math.round(e.y || e.changedTouches?.[0]?.screenY || 0);

      const dx = currentPageX - this.touchStartPosXStart;
      const dy = currentPageY - this.touchStartPosYStart;

      if (dx === 0 && dy === 0) {
        return;
      }

      this.offsetWidth += dx;
      this.offsetHeight += dy;

      this.correctOffsets();

      this.touchStartPosXStart = currentPageX;
      this.touchStartPosYStart = currentPageY;
    },
    onMoveInputEnd() {
      if (this.eventType !== null) {
        this.eventType = null;
      }
      cancelAnimationFrame(this.requestAnimationFrameId);
      // if (this.movingInterval) {
      //   clearInterval(this.movingInterval)
      // }
      this.moving = false;
    },
    onWheel(event) {
      // TODO: calc moveX, moveY

      const zoomFactor = event.deltaY < 0 ? (this.zoomStep) : (- this.zoomStep);
      let newScale = this.zoom + this.zoom * (zoomFactor - 1) / 10;

      // zoomStep
      if (newScale < this.zoomMin) {
        newScale = this.zoomMin;
      } else if (newScale > this.zoomMax) {
        newScale = this.zoomMax;
      }
      /* Round value */
      // if (newScale < (this.zoomMin + wheelZoomFactor)) {
      //   newScale = this.zoomMin;
      // } else if (newScale < this.zoomMax && newScale > this.zoomMax - wheelZoomFactor) {
      //   newScale = this.zoomMax;
      // }
      //
      // if (newScale < this.zoomMin || newScale > this.zoomMax) {
      //   return;
      // }

      if (newScale === this.zoom) {
        return;
      }
      this.zoom = newScale;
      this.activatorStyle = this.calcActivatorStyle();

      /* Get cursor position over image */
      // let xCenter = (event.clientX - this.elementPosition.left) - this.initialMoveX;
      // let yCenter = (event.clientY - this.elementPosition.top) - this.initialMoveY;

      this.calculateOffsetsLimits();
      this.correctOffsets();
    },
    onPinch(event) {
      // event.preventDefault();

      if (event?.touches.length !== 2) {
        if (this.eventType === 'pinch') {
          this.eventType = null;
        }
        return;
      }

      const getDistance = (touches) => {
        return Math.sqrt(
            Math.pow(touches[0].pageX - touches[1].pageX, 2) +
            Math.pow(touches[0].pageY - touches[1].pageY, 2)
        );
      }

      const touches = event.touches;

      // TODO FIXME ??

      if (!this.eventType) {
        this.eventType = 'pinch';

        this.initialMoveX = this.offsetWidth;
        this.initialMoveY = this.offsetHeight;

        this.initialDistance = getDistance(touches);

        const moveLeft0 = event.touches[0].clientX;
        const moveLeft1 = event.touches[1].clientX;
        const moveTop0 = event.touches[0].clientY;
        const moveTop1 = event.touches[1].clientY;

        this.moveXC = ((moveLeft0 + moveLeft1) / 2) - this.initialMoveX;
        this.moveYC = ((moveTop0 + moveTop1) / 2) - this.initialMoveY;
      }

      const distance = getDistance(touches);
      const k = distance / this.initialDistance;
      // const moveX = this.initialMoveX + (1 - k) * this.moveXC;
      // const moveY = this.initialMoveY + (1 - k) * this.moveYC;
      // const moveX = this.initialMoveX + k * (this.moveXC - document.body.clientWidth / 2);
      // const moveY = this.initialMoveY + k * (this.moveYC - document.body.clientHeight / 2);
      const moveX = this.initialMoveX - k * (this.moveXC - document.body.clientWidth / 2);
      const moveY = this.initialMoveY - k * (this.moveYC - document.body.clientHeight / 2);

      this.offsetWidth = Math.round(moveX);
      this.offsetHeight = Math.round(moveY);
      this.zoom = Math.round(this.zoom * k);

      if (this.zoom > this.zoomMax) {
        this.zoom = this.zoomMax;
      }
      if (this.zoom < this.zoomMin) {
        this.zoom = this.zoomMin;
      }
      // TODO: center after zoom

      this.calculateOffsetsLimits();
      this.correctOffsets();
    },
    onZoomInputStart() {
      // TODO
    },
    onZoomInput() {
      // TODO
    },
    onZoomInputEnd() {
      // TODO
    },
    correctOffsets() {
      if (this.offsetWidth < this.offsetWidthMin) {
        this.offsetWidth = this.offsetWidthMin;
      }
      if (this.offsetWidth > this.offsetWidthMax) {
        this.offsetWidth = this.offsetWidthMax;
      }
      if (this.offsetHeight < this.offsetHeightMin) {
        this.offsetHeight = this.offsetHeightMin;
      }
      if (this.offsetHeight > this.offsetHeightMax) {
        this.offsetHeight = this.offsetHeightMax;
      }
    },
    calculateOffsetsLimits() {
      const dw = Math.round((this.size.width * this.zoom - document.body.clientWidth) / 2);
      const dh = Math.round((this.size.height * this.zoom - document.body.clientHeight) / 2);
      if (this.size.width * this.zoom < document.body.clientWidth) {
        // for free moving objects
        // this.offsetWidthMin = dw;
        // this.offsetWidthMax = -dw;

        // for moving with restrictions (as tg)
        this.offsetWidthMin = 0;
        this.offsetWidthMax = 0;
      } else {
        this.offsetWidthMin = -dw;
        this.offsetWidthMax = dw;
      }
      if (this.size.height * this.zoom < document.body.clientHeight) {
        // for free moving objects
        // this.offsetHeightMin = dh;
        // this.offsetHeightMax = -dh;

        // for moving with restrictions (as tg)
        this.offsetHeightMin = 0;
        this.offsetHeightMax = 0;
      } else {
        this.offsetHeightMin = -dh;
        this.offsetHeightMax = dh;
      }
    },
    show(params = {}) {
      this.sourceRef = params.sourceRef;
      this.type = params.type;
      this.src = params.src;
      // this.item = params.item;
      this.size = params.size;
      // this.profile = params.profile;
      // this.zoom = params.zoom;
      this.srcPoster = params.srcPoster;
      this.zoom = 1;
      this.offsetWidth = 0;
      this.offsetHeight = 0;
      this.eventType = null;
      this.calculateOffsetsLimits();

      const el = this.sourceRef?.$el ? this.sourceRef.$el : this.sourceRef;

      if (!this.size) {
        const rect = el?.getBoundingClientRect();
        this.size = {
          width: rect.width,
          height: rect.height,
        };
      }

      this.isActivatorAnimated = false;
      this.isActivatorAnimating = false; // ???
      this.overlay = true;

      this.activatorStyle = this.calcActivatorStyle();

      if (this.type === 'video') {
        this.$nextTick(() => {
          this.isShowVideo = false;
          this.$refs.video.setAttribute('width', this.size.width);
          this.$refs.video.setAttribute('height', this.size.height);
          this.$refs.video.setAttribute('src', this.src);
          this.$refs.video.setAttribute('type', params.video?.type || 'video/mp4');
          this.$refs.video.load();
          this.$refs.video.play();

          // this.activatorStyle = this.calcActivatorStyle();
          this.onLoadActivator();

          setTimeout(() => {
            this.isShowVideo = true;
          }, 350);
        });
      }

      this.$nextTick(() => {
        this.$refs.container.addEventListener('touchstart', this.onMoveInputStart, false);
        this.$refs.container.addEventListener('touchmove', this.onMoveInput, {passive: true});
        this.$refs.container.addEventListener('touchend', this.onMoveInputEnd, false);

        this.$refs.container.addEventListener('mousedown', this.onMoveInputStart, false);
        this.$refs.container.addEventListener('mousemove', this.onMoveInput, {passive: true});
        this.$refs.container.addEventListener('mouseup', this.onMoveInputEnd, false);

        this.$refs.container.addEventListener('wheel', this.onWheel, {passive: true});
        this.$refs.container.addEventListener('touchmove', this.onPinch, false);

        this.isListenersExist = true;
      });
    },

    hide() {
      // TODO: fix bugs with exist timeout and interval

      this.clear();

      this.zoom = 1;
      this.offsetWidth = 0;
      this.offsetHeight = 0;

      cancelAnimationFrame(this.requestAnimationFrameId);

      const end = () => {
        setTimeout(() => {
          this.overlay = false;
        }, 300);

        this.isActivatorAnimated = false;
        this.isActivatorAnimating = false;
        this.activatorStyle = this.calcActivatorStyle();
      }

      if (this.type === 'video') {
        this.isShowVideo = false;
        this.$nextTick(() => {
          end();
        })
        return
      }

      end();
    },

    change(params = {}) {
      this.sourceRef = params.sourceRef;
      this.type = params.type;
      this.src = params.src;

      // this.item = params.item;
      this.size = params.size;

      if (!this.size) {
        const el = this.sourceRef?.$el ? this.sourceRef.$el : this.sourceRef;
        const rect = el?.getBoundingClientRect();
        this.size = {
          width: rect.width,
          height: rect.height,
        };
      }
    },

    onLoadActivator() {
      this.isActivatorAnimating = true;

      this.$nextTick(() => {
        this.activatorStyle = this.calcActivatorStyle();
      });
    },

    calcActivatorStyle() {
      const displayNone = {
        display: 'none',
      };
      const el = this.sourceRef?.$el ? this.sourceRef.$el : this.sourceRef;
      if (!el) {
        console.log('Undefined el')
        return displayNone;
      }
      const rect = el.getBoundingClientRect();
      if (!rect) {
        console.log('Undefined rect')
        return displayNone;
      }

      if (!this.isActivatorAnimating && !this.isActivatorAnimated) {
        return {
          position: 'absolute',
          width: `${rect.width}px`,
          height: `${rect.height}px`,
          left: `${rect.left}px`,
          top: `${rect.top}px`,
        };
      }

      // const w = Math.round(this.size.width * this.zoom);
      // const h = Math.round(this.size.height * this.zoom);
      return {
        position: 'absolute',
        // width: `${w}px`,
        // height: `${h}px`,
        // left: `calc(50% - ${w / 2}px)`,
        // top: `calc(50% - ${h / 2}px)`,
        width: `${this.size.width}px`,
        height: `${this.size.height}px`,
        left: `calc(50% - ${this.size.width / 2}px)`,
        top: `calc(50% - ${this.size.height / 2}px)`,
        transform: `translate(${this.offsetWidth}px, ${this.offsetHeight}px) scale(${this.zoom}, ${this.zoom})`,
        transition: this.moving ? 'none' : null,
      };
    },

    zoomIn() {
      this.zoom = this.zoom * this.zoomStep;
      if (this.zoom > this.zoomMax) {
        this.zoom = this.zoomMax;
      }
      this.calculateOffsetsLimits();
      this.correctOffsets();
      this.activatorStyle = this.calcActivatorStyle();
    },

    zoomOut() {
      this.zoom = this.zoom / this.zoomStep;
      if (this.zoom < this.zoomMin) {
        this.zoom = this.zoomMin;
      }
      this.calculateOffsetsLimits();
      this.correctOffsets();
      this.activatorStyle = this.calcActivatorStyle();
    },
    clear() {
      // if (this.movingInterval) {
      //   clearInterval(this.movingInterval)
      // }

      if (this.movingTimeout) {
        clearTimeout(this.movingTimeout)
      }

      if (this.isListenersExist) {
        this.$refs.container.removeEventListener('touchstart', this.onMoveInputStart);
        this.$refs.container.removeEventListener('touchmove', this.onMoveInput);
        this.$refs.container.removeEventListener('touchend', this.onMoveInputEnd);

        this.$refs.container.removeEventListener('mousedown', this.onMoveInputStart);
        this.$refs.container.removeEventListener('mousemove', this.onMoveInput);
        this.$refs.container.removeEventListener('mouseup', this.onMoveInputEnd);

        this.$refs.container.removeEventListener('wheel', this.onWheel);
        this.$refs.container.removeEventListener('touchmove', this.onPinch);

        this.isListenersExist = false;
      }
    },
  },
  beforeDestroy() {
    this.clear();
  },
};
</script>

<style scoped>
.transition-activator {
  transition: all 350ms ease;
  /*transition: scale 350ms ease, left 350ms ease, top 350ms ease, width 350ms ease, height 350ms ease, opacity 350ms ease;*/
  /*animation-fill-mode: forwards;*/
}
.transition-activator.animating {

}

.transition-activator-modal__content {
  padding: 0.5rem max(1.25rem, env(safe-area-inset-left));
}

.transition-activator-modal__content__button {
  margin-left: 4px;
  /*background-color: #212121; !* #212121cf *!*/
  z-index: 1;
}

.transition-activator-modal__content__button .v-icon {
  /*color: #ffffff2e;*/
  color: #bcbcbc;
}
</style>

<style>
 .transition-activator-modal > .v-overlay__content, .transition-activator-modal__container {
   width: 100%;
   height: 100%;
 }

 /*for stories*/
/*.transition-activator-modal .v-image__image--contain {*/
/*  background-color: rgb(33, 33, 33, 0.3);*/
/*}*/


</style>