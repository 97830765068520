import { render, staticRenderFns } from "./PageMaterialFAQ.vue?vue&type=template&id=3ba29668&scoped=true&"
import script from "./PageMaterialFAQ.vue?vue&type=script&lang=js&"
export * from "./PageMaterialFAQ.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ba29668",
  null
  
)

export default component.exports