<template>
  <WireframeNested title="Настройки">
    <LayoutOneColumn class="pa-2">
      <v-switch
          v-model="$store.settings.isThemeDark.value"
          label="Темная тема"
          inset
      />
<!--      TODO: fonst size-->
    </LayoutOneColumn>
  </WireframeNested>
</template>

<script>
import LayoutOneColumn from "@waln/components/layouts/LayoutOneColumn.vue";
import WireframeNested from "@waln/components/layouts/WireframeNested.vue";

export default {
  name: "PageSettings",
  components: {WireframeNested, LayoutOneColumn}
}
</script>

<style scoped>

</style>