import { render, staticRenderFns } from "./PageMaterialApproach.vue?vue&type=template&id=07e7e8dc&scoped=true&"
import script from "./PageMaterialApproach.vue?vue&type=script&lang=js&"
export * from "./PageMaterialApproach.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07e7e8dc",
  null
  
)

export default component.exports