import { render, staticRenderFns } from "./BarSnack.vue?vue&type=template&id=48a6c12d&scoped=true&"
import script from "./BarSnack.vue?vue&type=script&lang=js&"
export * from "./BarSnack.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48a6c12d",
  null
  
)

export default component.exports