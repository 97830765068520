<template>
  <WireframeNested :title="title">
    <LayoutOneColumn v-if="item" class="pa-2" :key="$route.params.id">
      <v-img
          :src="`https://storage.yandexcloud.net/lhs/static/${item.image}`"
          position="top center"
          class="mb-2"
          ref="image"
          @click="showImage"
          style="cursor: pointer; width: 100%;"
          aspect-ratio="4.87"
      />
      <h3 v-if="item.name" v-html="item.name" class="mb-4 text-squeeze"></h3>
      <p v-if="item.description" v-html="item.description"></p>
<!--      <video-->
<!--          width="320"-->
<!--          height="200"-->
<!--          autoplay-->
<!--          muted-->
<!--          loop-->
<!--          playsinline-->
<!--          class="ma-1"-->
<!--      >-->
<!--        <source-->
<!--            :src="`https://storage.yandexcloud.net/lhs/static/ndd-dataset.mp4`"-->
<!--            type="video/mp4"-->
<!--        >-->
<!--      </video>-->
<!--      <video-->
<!--          width="320"-->
<!--          height="200"-->
<!--          autoplay-->
<!--          muted-->
<!--          loop-->
<!--          playsinline-->
<!--          class="ma-1"-->
<!--      >-->
<!--        <source-->
<!--            :src="`https://storage.yandexcloud.net/lhs/static/ndd-dataset-dark.mp4`"-->
<!--            type="video/mp4"-->
<!--        >-->
<!--      </video>-->
      <div
          v-for="(card, cardIndex) of item.cards"
          :key="`card-${cardIndex}`"
      >
        <h4 v-if="card.title" class="text-squeeze">{{ card.title }}</h4>
        <p v-if="card.text">{{ card.text }}</p>
        <v-lazy
            :min-height="200"
            transition="fade-transition"
        >
        <div
            class="d-flex flex-wrap justify-center"
        >
          <video
              v-if="card.video"
              :key="`card-${cardIndex}-video`"
              width="320"
              height="200"
              autoplay
              muted
              loop
              playsinline
              class="ma-1"
              :ref="`video-${cardIndex}`"
              @click="showVideo(cardIndex)"
              style="cursor: pointer"
          >
            <source
                v-if="isIOS"
                :src="`https://storage.yandexcloud.net/lhs/static/${item.filesPrefix}-${card.video}_480x250.mp4`"
                type="video/mp4"
            >
            <source
                v-else
                :src="`https://storage.yandexcloud.net/lhs/static/${item.filesPrefix}-${card.video}_480x250.webm`"
                type="video/webm"
            >
          </video>
          <video
              v-if="card.video && item.hasDark"
              :key="`card-${cardIndex}-video-dark`"
              width="320"
              height="200"
              autoplay
              muted
              loop
              playsinline
              class="ma-1"
              :ref="`video-${cardIndex}-dark`"
              @click="showVideo(cardIndex, true)"
              style="cursor: pointer"
          >
            <source
                v-if="isIOS"
                :src="`https://storage.yandexcloud.net/lhs/static/${item.filesPrefix}-${card.video}-dark_480x250.mp4`"
                type="video/mp4"
            >
            <source
                v-else
                :src="`https://storage.yandexcloud.net/lhs/static/${item.filesPrefix}-${card.video}-dark_480x250.webm`"
                type="video/webm"
            >
          </video>
        </div>
<!--          <div v-else class="d-flex flex-wrap justify-center">-->
<!--            <video-->
<!--                v-if="card.video"-->
<!--                :key="`card-${cardIndex}-video`"-->
<!--                width="320"-->
<!--                height="200"-->
<!--                autoplay-->
<!--                controls-->
<!--                muted-->
<!--                loop-->
<!--                playsinline-->
<!--                class="ma-1"-->
<!--            >-->
<!--              <source-->
<!--                  :src="`https://storage.yandexcloud.net/lhs/static/${item.filesPrefix}-${card.video}_480x250.webm`"-->
<!--                  type="video/webm"-->
<!--              >-->
<!--            </video>-->
<!--            <video-->
<!--                v-if="card.video && item.hasDark"-->
<!--                :key="`card-${cardIndex}-video-dark`"-->
<!--                width="320"-->
<!--                height="200"-->
<!--                autoplay-->
<!--                controls-->
<!--                muted-->
<!--                loop-->
<!--                playsinline-->
<!--                class="ma-1"-->
<!--            >-->
<!--              <source-->
<!--                  :src="`https://storage.yandexcloud.net/lhs/static/${item.filesPrefix}-${card.video}-dark_480x250.webm`"-->
<!--                  type="video/webm"-->
<!--              >-->
<!--            </video>-->
<!--          </div>-->
        </v-lazy>
      </div>

      <MediaViewer ref="mediaViewer"/>

    </LayoutOneColumn>
    <LayoutOneColumn v-else class="pa-2">
      <h2 class="text-squeeze">Страница не найдена (404)</h2>
    </LayoutOneColumn>
  </WireframeNested>
</template>

<script>
import LayoutOneColumn from "@waln/components/layouts/LayoutOneColumn.vue";
import WireframeNested from "@waln/components/layouts/WireframeNested.vue";
import MediaViewer from "@waln/components/base/ViewerTools/MediaViewer.vue";
import PatchWindowSize from '@waln/plugins/PatchWindowSize';

// TODO: refactor to .env
// const srcRoot = '../../../../../../temp/lh-files';

const items = {
  ndd: {
    title: 'Расчет НДД',
    name: 'Система расчета налога на дополнительный доход',
    description: `<p>Реализована система расчета <a href="http://www.consultant.ru/document/cons_doc_LAW_28165/e1bdc5405d9567b215b64b198464590b24f76344/" target="_blank">налога на дополнительный доход</a> для <a href="https://www.gazprom.ru/" target="_blank">ПАО «Газпром»</a>. Разработана клиентская часть приложения совместно с командой разработчиков Газпрома. В системе более 50 различных таблиц с сущностями для расчета. Проект успешно реализован, внедрен и используется внутри компании ПАО «Газпром».</p>`,
    filesPrefix: 'ndd',
    hasDark: true,
    image: 'ndd-demo_1512x310.png',
    imageFull: 'ndd-demo_1512x1134.png',
    cards: [
      {
        title: 'Авторизация',
        text: 'Авторизация реализована в keycloak, а на стороне клиента в качестве входа выбирается бизнес единица (он же лицензируемый участок), с которым будет вестись работа.',
        video: 'login',
      },
      {
        title: 'Демонстрация основных экранов',
        video: 'tabs',
      },
      {
        title: 'Демонстрация работы с наборам данных, используемыми для расчета',
        video: 'datasets',
      },
      {
        title: 'Демонстрация работы с определенным набором данных',
        video: 'dataset',
      },
      {
        title: 'Демонстрация работы с элементом определенным набора данных',
        video: 'dataset-item-1',
      },
      {
        title: 'Демонстрация валидации полей элемента набора данных',
        video: 'dataset-item-2',
      },
      {
        title: 'Граф данных',
        text: 'Для удобства навигации была реализована страница графа наборов данных, который строится динамически на основе некоторых правил.\n',
        video: 'graph',
      },
    ],
  },
  nna: {
    key: 'nna',
    title: 'НСА',
    name: 'Национальный сетевой акселератор',
    // (для развития технологичных стартапов)
    description: `<p>Совместно с ассоциацией <a href="https://leaders.club/" target="_blank">Клуб лидеров</a> разработан прототип для <a href="https://asi.ru/" target="_blank">Агентства Стратегических Инициатив</a>. Потом случилась пандемия, финансирование отменили, проект заморозили. Лень тут писать все детали описания платформы, просто поверьте на слово - она очень большая. В демонстрациях ниже меньше половины реализованной функциональности. Если сильно интересно про нее узнать - расскажем на созвоне.</p>`,
    filesPrefix: 'nna',
    image: 'nna-demo_1512x310.png',
    imageFull: 'nna-demo_1512x1134.png',
    cards: [
      {
        title: 'Демонстрация авторизации и кабинета',
        video: 'login-and-cabinet',
      },
      {
        title: 'Демонстрация регистрации',
        video: 'registration',
      },
      {
        // Демонстрация компаний и вакансий
        title: 'Демонстрация компании',
        video: 'company',
      },
      {
        title: 'Демонстрация эксперта',
        video: 'expert',
      },
      {
        // демонстрация сложных кейсов работы с экспертизой
        title: 'Демонстрация экспертизы',
        video: 'expertise-work',
      },
      {
        // демонстрация сложных кейсов работы с вакансиями
        title: 'Демонстрация вакансии',
        video: 'vacancy-work',
      },
    ],
  },
};

export default {
  name: "PagePortfolioItem",
  components: {MediaViewer, WireframeNested, LayoutOneColumn},
  data: () => ({
    items,
  }),
  computed: {
    item() {
      if (!this.$route.params.id) {
        return null;
      }
      return this.items[this.$route.params.id];
    },
    title() {
      if (this.item?.title) {
        return this.item.title;
      }
      if (this.$route.params.id) {
        return this.$route.params.id;
      }
      return 'Not found';
    },
    isIOS() {
      return PatchWindowSize.IS_IOS;
    },
    // srcItemImage() {
    //   // TODO: refactor production
    //   // console.log(`${srcRoot}/production/${this.item.image}`)
    //   // console.log(require(`https://storage.yandexcloud.net/lhs/static/${this.item.image}`))
    //   console.log(require(`${srcRoot}/production/${this.item.image}`))
    //   // return require(`${this.srcRoot}/production/${this.item.image}`)
    //   const p = `${srcRoot}/production/${this.item.image}`;
    //   console.log(p)
    //   const t = require(p)
    //   return t;
    // },
  },
  methods: {
    // TODO: refactor to viewer component
    calculateOptimalSize(size) {
      const verticalOffset = 60;

      if (document.body.clientHeight < verticalOffset * 2) {
        return {
          width: document.body.clientWidth,
          height: document.body.clientHeight,
        };
      }

      const maxWidth = document.body.clientWidth * 0.9;
      const maxHeight =
          document.body.clientHeight >= 1200 ?
              document.body.clientHeight * 0.9 :
              (document.body.clientHeight - verticalOffset * 2);
      let width = size.width;
      let height = size.height;
      if (width > maxWidth) {
        height = height / width * maxWidth;
        width = maxWidth;
      }
      if (height > maxHeight) {
        width = width / height * maxHeight;
        height = maxHeight;
      }
      return {
        width: Math.round(width),
        height: Math.round(height),
      };
    },
    showImage() {
      const sourceRef = this.$refs.image;
      const src = `https://storage.yandexcloud.net/lhs/static/${this.item.imageFull}`;
      this.$refs.mediaViewer.show({
        sourceRef,
        type: 'image',
        src,
        size: this.calculateOptimalSize({width: 2700, height: 2025}),
      });
    },
    showVideo(index, dark = false) {
      const refKey = `video-${index}` + (dark ? '-dark' : '');
      const sourceRef = this.$refs[refKey][0];
      if (this.isIOS) {
        sourceRef?.webkitEnterFullScreen?.();
        return;
      }
      const extension = this.isIOS ? 'mp4' : 'webm';
      const src = `https://storage.yandexcloud.net/lhs/static/${this.item.filesPrefix}-${this.item.cards[index].video}${dark ? '-dark' : ''}.${extension}`;
      const srcPoster = `https://storage.yandexcloud.net/lhs/static/${this.item.filesPrefix}-${this.item.cards[index].video}${dark ? '-dark' : ''}_480x250.png`;
      // this.$nextTick(() => {
        this.$refs.mediaViewer.show({
          sourceRef,
          type: 'video',
          src,
          size: this.calculateOptimalSize({width: 1512, height: 788}),
          video: {
            type: 'webm',
          },
          srcPoster,
        });
      // });
    },
  },
};
</script>

<style scoped>

</style>